import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import BuildInResumePageFooter from "../../components/BuildInResumePageFooter";
import { getEmployer } from "../../helpers/backendHelper";
import CreateEmployersAccountFormFive from "./JobDescription";
import CreateEmployersAccountFormThree from "../employers/CreateEmployersAccountFormThree";
import CreateEmployersAccountFormTwo from "../employers/CreateEmployersAccountFormTwo";
import ReviewEmployersAccount from "../employers/ReviewEmployersAccount";
import SalaryDetails from "./SalaryDetails";
import JobDescription from "./JobDescription";



function CreateEmployersAccountHomePage() {

  const { user } = useSelector(state => ({
    user: state.Login.user,
  }));

  const [userData, setUserData] = useState();
  const [isProfileUpdated, setIsProfileUpdated] = useState(true)


  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await getEmployer();
      if (response && response.statusCode === 200) {
        const data = response.employer;
         setUserData(data);
        setIsProfileUpdated(data.isProfileUpdate)
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
   const [step, setStep] = useState(1);
  const [jobFormType, setJobFormType] = useState("");
  const [jobInputData, setJobInputData] = useState();
  const handleContinue = (type, inputData) => {

     setStep(step + 1);
     setJobFormType(type);
    setJobInputData((prevData) => ({ ...prevData, ...inputData }));
  };
  const navigate = useNavigate();
  useEffect(() => {
     setIsProfileUpdated(true)
  }, [user])

  if (!isProfileUpdated) {
    Swal.fire({
      title: "Update Your Profile!",
      text: "To post for the job, you need to update your profile first.",
      icon: "info",
      // showCancelButton: true,
      confirmButtonColor: "#3085d6",
      // cancelButtonColor: "#d33",
      confirmButtonText: "OK",
      // cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate('/employer/employerProfile')
      }

    })
  }
  return (
    <div>
      {/* <Navbar /> */}
      <div className="container"> 
        {step === 1 && (
          // <CreateEmployersAccountFormOne onContinue={handleContinue} />
          <CreateEmployersAccountFormTwo onContinue={handleContinue} />
        )}
        {step === 2 && (
          <CreateEmployersAccountFormThree onContinue={handleContinue} />
        )}
        {step === 3 && (
          <SalaryDetails onContinue={handleContinue} />
        )}
        {step === 4 && (
          <JobDescription onContinue={handleContinue} />
        )}
        {/* {step === 5 && (
          <CreateEmployersAccountFormSix onContinue={handleContinue} />
        )} */}
        {/* {step === 6 && (
        )} */}
        {step === 5 && <ReviewEmployersAccount
          onContinue={handleContinue}
          jobInputData={jobInputData}
        />}
      </div>
      <BuildInResumePageFooter />
    </div>
  );
}

export default CreateEmployersAccountHomePage;
