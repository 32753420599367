import React, { useState, useRef } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import CloseIcon from "./CloseIcon";
import ImageCropper from "./ImageCropper";
import CameraCapture from "./CameraCapture";
import * as url from "../../helpers/urlHelper"
import '../../styles/CustomProfileModal.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import profile_image from '../../assets/images/employer_profile.svg';
import material_symbols_upload from '../../assets/images/material-symbols_upload.png';
import ph_camera from '../../assets/images/ph_camera.png';
import remove from '../../assets/images/pajamas_remove.png';
import '../../styles/CustomProfileModal.css'

const CustomProfileModal = ({ updateAvatar, closeModal, showModal,editableImage }) => {
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [showCamera, setShowCamera] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const [fileName,setFileName] = useState(null);
  const [file,setFile] = useState({})

  const fileInputRef = useRef(null);

  const handleUploadPhoto = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      setShowImageCropper(true);

    }
  };

  // const handleFileSelect = (event) => {
  //   const file = event.target.files[0];
  //   setFile(file)
  //   setFileName(file.name)
  // };

  const handleTakePhoto = () => setShowCamera(true);
  const handleRemovePhoto = () => updateAvatar(null);

  return (
    <Modal show={showModal} onHide={closeModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Image</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-white text-light">
       <img src={profile_image} className="mx-auto d-block" height="140px" ></img>
      <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        
        {!showImageCropper && !showCamera && (
          <div className="d-flex flex-column align-items-center">
            <Button onClick={handleUploadPhoto} className="mb-3 w-75 btn-green">
              <Row className="d-flex justify-content-center">
                <Col md={3} className="text-end">
                <img src={material_symbols_upload} className="mx-auto d-block "  />
                </Col>
                <Col md={7} className="text-start" >
                Upload Photo
                </Col>
              </Row>
            </Button>
            <Button onClick={handleTakePhoto} className="mb-3 w-75 btn-green">
            <Row className="d-flex justify-content-center">
                <Col md={3} className="text-end">
                <img src={ph_camera} className="mx-auto d-block " height={24} />
                </Col>
                <Col md={7} className="text-start" >
                Take Photo
                </Col>
              </Row>
            </Button>
            <Button  onClick={handleRemovePhoto} className="w-75 btn-green"> 
            <Row className="d-flex justify-content-center">
                <Col md={3} className="text-end">
                <img src={remove} className="mx-auto d-block mt-1"  height={18}/>
                </Col>
                <Col md={7} className="text-start" >
                Remove Photo
                </Col>
              </Row>
            </Button>
          </div>
        )}
        {showImageCropper && selectedFile && (
          <ImageCropper
            file={selectedFile}
            updateAvatar={updateAvatar}
            closeModal={() => {
              setShowImageCropper(false);
              closeModal();
            }}
            editableImage={editableImage}
          />
        )}
        {showCamera && (
          <CameraCapture
            updateAvatar={updateAvatar}
            closeModal={() => {
              setShowCamera(false);
              closeModal();
            }}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CustomProfileModal;
