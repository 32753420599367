import React, { useEffect, useState } from "react";

import { Link, Navigate, useNavigate } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Navbar,
    Dropdown,
    NavDropdown,
    InputGroup,
    Button,
    Tab,
    Nav,
    Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faBars,
    faQuestionCircle,
    faBell,
    faEnvelope,
    faUser,
    faAngleDown,
    faBriefcase,
    faPhoneAlt,
    faBullhorn,
    faSearch,
    faClipboardCheck,
    faChartBar,
    faTools,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import { logoutToken } from "../../helpers/utility";
import { IoMdNotificationsOutline } from "react-icons/io";
import { TbMessageDots } from "react-icons/tb";
import { IoSettingsOutline } from "react-icons/io5";

const EmployerNavBar = () => {

    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));
    const navigate = useNavigate();
    
    // const [sidebarExpanded, setSidebarExpanded] = useState(false);

    // const toggleSidebar = () => {
    //     setSidebarExpanded(!sidebarExpanded);
    //     const wrapper = document.getElementById("wrapper");
    //     wrapper.classList.toggle("toggled");
    // };

    const handleLogout = async () => {
        await logoutToken();
    }
    const handleProfileClick = async () => {
        navigate(`/employer/employerProfile`);
    }

    return (
    <>
        <Navbar expand="lg" className="bg-transparent">
            <Container fluid className="d-flex justify-content-">
                {/* <Link
                    to="#"
                    className="text-decoration-none fw-bold text-dark icon-size-topbar"
                    onClick={toggleSidebar}
                >
                    <FontAwesomeIcon icon={faBars} id="sidebar" className="me-2 " />{" "}
                </Link> */}
                <InputGroup className="input-search">
                    <Form.Control 
                    type="text"
                    placeholder="Search here"
                    size="md"
                    />
                    <InputGroup.Text>
                        <i className="bi bi-search fs-4"></i>
                    </InputGroup.Text>
                </InputGroup>
                
                <Navbar.Toggle aria-controls="navbarScroll" />
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="me-auto my-2 my-lg-0"
                        style={{ maxHeight: '100px' }}
                        navbarScroll
                    >
                    </Nav>
                            
                    <Nav.Link href="#action1" className="px-2 pb-1 my-2 my-md-0 border rounded mx-2">
                        <IoMdNotificationsOutline className="fw-bolder" style={{color : "#2D9CDB"}} />
                    </Nav.Link>
                    <Nav.Link href="#action1" className="px-2 pb-1 my-2 my-md-0 border rounded mx-2">
                        <TbMessageDots className="fw-bolder" style={{color : "#2D9CDB"}} />
                    </Nav.Link>
                    <Nav.Link href="#action1" className="px-2 pb-1 my-2 my-md-0 border rounded mx-2 me-5">
                        <IoSettingsOutline className="fw-bolder" style={{color : "#2D9CDB"}} />
                    </Nav.Link>

                    <Nav navbarScroll>
                        <span className="fs-5 me-3 my-2">First Name</span>
                        <NavDropdown
                            title={<FontAwesomeIcon className="nav-text fs-5" icon={faUser} />}
                            align={{md:"end"}}
                            className="nav-text"
                        >
                            <NavDropdown.Item className="nav-text" onClick={handleProfileClick}>Profile</NavDropdown.Item>
                            <NavDropdown.Item className="nav-text" onClick={handleLogout}>Logout</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    </>)
}

export default EmployerNavBar;