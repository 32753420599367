import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaLinkedin, FaTwitter, FaFacebook, FaInstagram } from "react-icons/fa";

function FooterAccessibilityPageFooter() {
  return (
    <footer>
      <Container>
        <Row className="pt-2">
          <Col md={6} className="text-start ">
            © {new Date().getFullYear()} Your Company Name
          </Col>
          <Col md={6} className="d-flex justify-content-end">
            <div>
              <a href="https://www.linkedin.com/" className="text-dark me-3">
                <FaLinkedin size={30} />
              </a>
              <a href="https://twitter.com/" className="text-dark me-3">
                <FaTwitter size={30} />
              </a>
              <a href="https://www.facebook.com/" className="text-dark me-3">
                <FaFacebook size={30} />
              </a>
              <a href="https://www.instagram.com/" className="text-dark me-3">
                <FaInstagram size={30} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default FooterAccessibilityPageFooter;
