import React from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import "../../styles/Forgetpsw.css";
import { Container, Row, Col, Card, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import pswimg from "../../assets/images/psw-flow2.svg";
import OTPInput from "react-otp-input";

const Mobileotp=()=>{
  const location=useLocation();
  const {mobilenumber}=location.state||{};

    return(
        <>
          <Navbar />
          <div className="d-flex pswd">
        <Container className="mt-5">
          <Row className="pswd-row d-flex justify-content-between align-items-center">
            <Col lg={5}>
              <Card className="pswd-content">
                <h4 className="fw-bold">Enter Your Code</h4>
                <p className="p-title">
                    We sent to code to your Mobile Number:<a href="#">{mobilenumber}</a>
                </p>
                <Col md={8} lg={6} xl={5}>
                                <div className="my-3">
                                    <OTPInput
                                        // value={otp}
                                        // inputStyle="otp-input"
                                        // onChange={setOtp}
                                        inputType='password'
                                        className='otp-box'
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} style={{
                                            width: '30px',
                                            borderWidth: '1px',
                                            borderColor: 'black',
                                            borderRadius: '8px',
                                            padding: '5px',
                                             backgroundColor: 'transparent',
                                             margin:"10px",
                                        }} />}
                                    />
                                </div>
                </Col>
                <p> Didn't Receive the email? <span className="text-decoration-underline fw-bolder">
                 Click to Resend
                </span></p>
               
                <Link to='/editpassword'>
                <button type="submit" className="btn btn-color-primary input-height w-100 py-2 mt-3" >
                            <strong>Continue</strong>
                        </button>
                </Link>
              </Card>
            </Col>
            <Col lg={5}>
              <Image className="pswd-img" src={pswimg} alt="img" fluid />
            </Col>
          </Row>
        </Container>
      </div>
          <Footer />

        </>
    )
}
export default Mobileotp;