import React from "react";
import TwoFactorAuth from "../profile/Employer/TwoFactorAuth";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import PinVerify from "../../components/common/PinVerify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const TwoFactorVerification = () => {

    
    const navigate = useNavigate();
  const { user } = useSelector(state => ({
    user: state.Login.user,
    }));

    const email = user.email
    const handleComplete = () =>{
        navigate('/employers/jobpostdashbord')
    }

    console.log('inside the twofactor',user)
    return (
            <>     
                <Container fluid className="mt-3">
                            <Row className="justify-content-center">
                                <Col md={6}>
                                    <Card>
                                        <CardHeader>
                                            <p>Two Factor Verification</p>
                                        </CardHeader>
                                        <CardBody>
                                        <PinVerify
                                                emailorMobileNumber={email}
                                                type="twoFa"
                                                userType="Employer"
                                                onComplete={handleComplete}
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                </Container>
             </>
             )

}
export default TwoFactorVerification;