import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";
import facebook from '../assets/images/facebook.png';
import instagram from '../assets/images/instagram.png';
import twitter from '../assets/images/twitter.png';
import youtube from '../assets/images/youtube.png';
import appstore from '../assets/images/appstore.png';
import playstore from '../assets/images/playstore.png';
import '../assets/css/footer.css' 

const Footer = () => {
  return (
    <footer className="footer-bg-color footer-link mt-lg-3 mt-3">
      <Container>
        <Row className="pt-5">
          <Col md={5} className="d-flex align-items-center">
            <a href="#">
              <Image src={facebook} className="me-1"/>
            </a>
            <a href="#">
              <Image src={instagram} className="me-1"/>
            </a>
            <a href="#">
              <Image src={twitter} className="me-1"/>
            </a>
            <a href="#">
              <Image src={youtube} className="me-1"/>
            </a>
          </Col>
          <Col md={2}>
            <h5 className="footer-heading">Product</h5>
            <ul className="list-unstyled">
              <li className="footer-link">
                <Link to="/nonIt">
                  Non IT Jobs
                </Link>
              </li>
              <li className="footer-link">
                <Link to="/It" >
                  IT Jobs
                </Link>
              </li>
              <li className="footer-link">
                <Link to="/jobseeker" >
                  Job Seekers
                </Link>
              </li>
              <li className="footer-link">
                <Link to="" >
                  Employers
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={2}>
            <h5 className="footer-heading">Company</h5>
            <ul className="list-unstyled">
              <li className="footer-link">
                <Link to="/about" >
                  About Us
                </Link>
              </li>
              <li className="footer-link">
                <Link to="/contact" >
                  Contact Us
                </Link>
              </li>
              <li  className="footer-link">
                <Link to="/Feedback">
                  Send feedback
                </Link>
              </li>
              <li  className="footer-link">
                <Link to="/jobs">
                  HTML Sitemap
                </Link>
              </li>
              <li  className="footer-link">
                <Link to="/jobs">
                  XML Sitemap
                </Link>
              </li>
            </ul>
          </Col>
          <Col md={3}>
            <h5 className="footer-heading">Get in touch</h5>
            <p className="footer-link"> <FontAwesomeIcon icon={faEnvelope} className="me-2" />info@example.com</p>
            <p className="footer-link"><FontAwesomeIcon icon={faPhone} className="me-2" />Toll No: +1 (123) 456-7890</p>
            <p className="footer-link"><FontAwesomeIcon icon={faPhone} className="me-2" />Toll Free: +1 (123) 456-7890</p>
          </Col>
        </Row>
      </Container>
    <hr />
    <Container>
    <Row className="p-3">
          <Col lg={12} md={12} sm={12} className="mobile-view-footer mb-3">
            <h5 className="me-3">Download the App</h5>
            <Link to="#" className="me-2"><Image src={appstore} /></Link>
            <Link to="#"><Image src={playstore} /></Link>
          </Col>
          <Col lg={12} md={12} sm={12} className="mobile-view-footer">
              <Link to="#" className="m-3">Security & fraud</Link>
              <Link to="#" className="m-3">Privacy Policy</Link>
              <Link to="#" className="m-3">Terms of Use</Link>
              <Link to="#" className="m-3">Beware of Fraudsters</Link>
              <Link to="#" className="m-3">Be Safe</Link>
              <Link to="#" className="m-3">complaints</Link>
          </Col>
        </Row>
    </Container>
    <hr />
      <Container
        className=" pb-3 footer-bg-color"
      >
        <Row className="pt-2 p-2 d-flex justify-content-center">
          <Col xs={12} sm={12} md={6} lg={6} className="text-center">
            © {new Date().getFullYear()} Your Company Name. {" "}
            <Link to="/Accessiblility" className="footer-link mx-3">
              All Rights Reserved.
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
