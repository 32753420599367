import React, { useEffect } from "react";
import { Navigate, Route } from "react-router-dom";
 import { useDispatch } from "react-redux";
 
 import { getTokenValue,IsNullOrEmpty } from "../helpers/utility";
import { useProfile } from "../components/Hooks/UseProfile";
import { setAuthorization } from "../helpers/apiHelper";


 const AuthProtected = (props) => {
  const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  useEffect(() => {
    if (userProfile && token) {
       setAuthorization(token);
    } else if (!userProfile && loading && !token) {
      // dispatch(logoutUser());
    }
  }, [token, userProfile, loading, dispatch]);

  var user = getTokenValue(token);
  /*
    Navigate is un-auth access protected routes via url
    */
   if (!userProfile && !token) {
    return (
      <Navigate to={{ pathname: "/signup", state: { from: props.location } }} />
    );
  }

//    if (!IsNullOrEmpty(token) && (user.firstTimeUser || !user.isMobileVerified || !user.isEmailVerified) && location.pathname !== "/verify") {

//   }

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (<> <Component {...props} /> </>);
      }}
    />
  );
};

export { AuthProtected, AccessRoute };