import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FcGoogle } from "react-icons/fc";

import { useSelector } from "react-redux";
import withRouter from '../../components/common/withRouter';
import { employerSendOtp, jobSeekerSendOtp } from '../../helpers/backendHelper';
import Swal from 'sweetalert2';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import Navbar from "../../components/Navbar";
import Footer from "../../components/footerAccessibilityPageFooter";
import LoginImage from "../../assets/images/on-removebg-preview.svg";

const NewLoginNext = () => {
    const { existingUser, error, loading, errorMsg, email, mobileNumber } = useSelector(state => ({
        existingUser: state.Login.existingUser,
        error: state.Login.error,
        loading: state.Login.loading,
        errorMsg: state.Login.errorMsg,
        email: state.Login.email ? state.Login.email : sessionStorage.getItem('email'),
        mobileNumber: state.Login.mobileNumber ? state.Login.mobileNumber :sessionStorage.getItem('mobileNUmber') ,
        userType: state.Login.userType
    }));

    const navigate = useNavigate();
    console.log('email loginnext',email)
    const userType = existingUser ? existingUser.userType : null
    console.log('existingUser', existingUser)
    //const email = localStorage.getItem("email");
    const isGoogleDomain = (email) => {

        const domain = email.split('@')[1];
        return domain === 'google.com' || domain === 'gmail.com';
    }
    const getLinkTo = () => {
        if (existingUser.isExistingUser) {
            if (mobileNumber) {
                return '/auth/otp/signIn';
            } else if (isGoogleDomain(email)) {
                return '/auth/otp/signIn';
            } else {
                return '/auth/signIn';
            }
        } else {
            return '/auth/userType';
        }
    };
    const getLinkText = (isSignUpWithPasword) => {

        if (isSignUpWithPasword) {
            return 'Sign in with password';
        }
        if (existingUser.isExistingUser) {
            if (mobileNumber) {
                return 'Sign in with login code';
            } else if (isGoogleDomain(email)) {
                return 'Sign in with login code';
            } else {
                return 'Sign in with password';
            }
        } else {
            return 'Sign up with password';
        }
    };

    const getStateObject = () => {
        if (email && !mobileNumber) {
            return { email: email };
        } else if (mobileNumber) {
            return { mobileNumber: mobileNumber };
        } else {
            return null;
        }
    };

    const handleSignInWithLoginCode = async () => {
        try {
            console.log('email', email, 'mobileNumber', mobileNumber)
            let payload = {}
            if (email) {
                payload.email = email
                payload.isAccountVerifiedApi = false
            } else if (mobileNumber) {
                payload.mobileNumber = mobileNumber
            }
            else {

            }
            if (userType == 'JobSeeker') {
                await jobSeekerSendOtp(payload).then((x) => {
                }).catch((e) => {
                    console.log('error', e)
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PINs",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {
                        if (x.isDismissed) {
                            // onClose();
                        }
                    })
                })
            }
            else if (userType == 'Employer') {
                await employerSendOtp({ email: email }).then((x) => {
                }).catch((e) => {
                    console.log('error', e)
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PINs",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {
                        if (x.isDismissed) {
                            // onClose();
                        }
                    })
                })
            }
        } catch (e) {

        }

    }


    const handleSignInWithPassword = () => {
        navigate('/auth/signIn');
    };



    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6}>
                            <Card className='signup-card text-dark'>
                                <h5 > {existingUser.isExistingUser ? 'Welcome Back' : 'Create your Account'}</h5>
                                <p>Keep your account Safe</p>
                                <p>Continue as <a href='www.email.com' >{email ? email : null}</a><Link to='/signUp' className='text-color-dark ms-2' style={{ color: "#3A95CD" }} >(Not You?)</Link></p>
                                <p>By creating an account or signing in,you understand and agree to SearchJobs's <Link className='text-color-dark'>Terms.</Link> you also acknowledge our  <Link className='text-color-dark'>Cookie</Link> and <Link className='text-color-dark'>Privacy</Link> policies.you will receive marketing  messeges from SearchJobs and may opt out at any time by following the unsubscribe link in our messeges,or detailed in our terms. </p>
                                {isGoogleDomain(email) && (
                                    // <Link
                                    //     className='text-email-color fw-boldest'
                                    //     style={{ color: "#3A95CD" }}
                                    //     to={getLinkTo()}
                                    //     state={getStateObject()}
                                    //     onClick={() => {
                                    //         if (existingUser.isExistingUser && email && isGoogleDomain(email)) {
                                    //             handleSignInWithLoginCode();
                                    //         }
                                    //     }}
                                    // >
                                    //     {getLinkText(false)}
                                    // </Link>
                                    <div className="text-start mt-2">
                                        <Link
                                        className="text-email-color fw-boldest"
                                        style={{ color: '#3A95CD'}}
                                        to={getLinkTo()}
                                        state={getStateObject()}
                                        onClick={() => {
                                            if (existingUser.isExistingUser && email && isGoogleDomain(email)) {
                                            handleSignInWithLoginCode();
                                            }
                                        }}
                                        >
                                        {getLinkText(false)}
                                        </Link>
                                    </div>
                                )}
                                 <button
                                    className="btn btn-link mt-2 text-email-color fw-boldest px-0"
                                    style={{ width: "156px",color: "#3A95CD" }}
                                    onClick={handleSignInWithPassword}
                                >
                                    {getLinkText(true)}
                                </button>

                            </Card>
                        </Col>

                        <Col md={5} className='d-none d-md-block'>
                            <Image className='login-img' src={LoginImage} fluid />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default withRouter(NewLoginNext)