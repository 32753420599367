import React, { useState } from "react";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCheck } from "@fortawesome/free-solid-svg-icons";
import EmployerNavbar from "../../components/Employer/EmployerNavbar"
import '../../styles/Employers.css'
import EmployerSidebar from "../../components/EmployerSidebar";
import { useFormik } from 'formik';
import * as Yup from 'yup';

function CreateEmployersAccountFormThree({ onContinue }) {
  const validationSchema = Yup.object().shape({
    numberOfPeople: Yup.string().required('Number of people is required'),
    educationLevel: Yup.string().required('Education level is required'),
    recruitmentTimeline: Yup.string().required('Recruitment timeline is required'),
  });

  const formik = useFormik({
    initialValues: {
      jobType:"",
      numberOfPeople: "",
      educationLevel: "",
      recruitmentTimeline: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const jobTypeString = selectedJobTypes.join(', ');
      const data = {
        jobDetails: {
          jobType:selectedJobTypes.join(", "),
          numberOfPositions: values.numberOfPeople,
          educationLevel: values.educationLevel,
          recruitmentTimeline: values.recruitmentTimeline,
        }
      };
      console.log('data jobdetails',data)
      onContinue("", data);
    },
  });

  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [selectedSchedules, setSelectedSchedules] = useState([]);
  const [hasPlannedStartDate, setHasPlannedStartDate] = useState(false);

  const toggleSelection = (item, category) => {
    switch (category) {
      case "jobType":
         setSelectedJobTypes((prevSelected) =>
          prevSelected.includes(item)
            ? prevSelected.filter((selected) => selected !== item)
            : [...prevSelected, item]
        );
        break;
      case "schedule":
        setSelectedSchedules((prevSelected) =>
          prevSelected.includes(item)
            ? prevSelected.filter((selected) => selected !== item)
            : [...prevSelected, item]
        );
        break;
      default:
        break;
    }
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  return (
    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />
      <div id="navbar-wrapper">
        <EmployerNavbar />
      </div>
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={8} className="p-0">
            <Card className="p-lg-5 p-3 bg-light border-0 rounded">
              <Row>
                <Col>
                  <h4>Add job details</h4>
                </Col>
              </Row>
              <hr />
              <Row>
              <h5 className=" mb-3 fw-bold employers-lable">Job type *</h5>
                {[
                  "Full-time",
                  "Part-time"
                ].map((type) => (
                  <Col md={4} xs={6} className="mb-2">
                    <Button
                      key={type}
                      variant={
                        selectedJobTypes.includes(type) ? "success" : "light"
                      }
                      className="btn border job-detail-text-size form-control"
                      onClick={() => toggleSelection(type, "jobType")}
                    >
                      <FontAwesomeIcon
                        icon={
                          selectedJobTypes.includes(type) ? faCheck : faPlus
                        }
                        className="me-2 job-detail-text-size"
                      />
                      {type}
                    </Button>
                  </Col>
                ))}
              </Row>
              <hr />
              <Row>
              <h5 className="fw-bold employers-lable mb-3">Schedule</h5>
                {[
                  "Day Shift",
                  "Rotational Shift",
                  "Night Shift",
                  "US Shift",
                  "UK Shift"
                ].map((schedule) => (
                  <Col md={4} xs={6} className="mb-2">
                    <Button
                      key={schedule}
                      variant={
                        schedule.includes(schedule)
                          ? "success"
                          : "light"
                      }
                      className="btn border job-detail-text-size form-control"
                      onClick={() => toggleSelection(schedule, "schedule")}
                    >
                      <FontAwesomeIcon
                        icon={
                          selectedSchedules.includes(schedule)
                            ? faCheck
                            : faPlus
                        }
                        className="me-2 job-detail-text-size"
                      />
                      {schedule}
                    </Button>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col>
                  <Form onSubmit={formik.handleSubmit}>
                    <Form.Group controlId="numberOfPeople">
                      <Form.Label className="fw-bold   employers-lable">
                        Number of people you wish to hire for this job *
                      </Form.Label>
                      <Form.Select
                        value={formik.values.numberOfPeople}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.numberOfPeople && formik.errors.numberOfPeople}
                      >
                        <option value="">Select an option</option>
                        {[...Array(9)].map((_, index) => (
                          <option key={index + 1} value={index + 1}>
                            {index + 1}
                          </option>
                        ))}
                        <option value="10+">10+</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.touched.numberOfPeople && formik.errors.numberOfPeople}
                      </Form.Control.Feedback>
                    </Form.Group>
                    
                    {/* Recruitment Timeline */}
                    <Form.Group controlId="recruitmentTimeline">
                      <Form.Label className="fw-bold   employers-lable">
                        Recruitment timeline for this job *
                      </Form.Label>
                      <Form.Select
                        value={formik.values.recruitmentTimeline}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.recruitmentTimeline && formik.errors.recruitmentTimeline}
                      >
                        <option value="">Select an option</option>
                        <option value="1 to 3 days">1 to 3 days</option>
                        <option value="3 to 7 days">3 to 7 days</option>
                        <option value="1 to 2 weeks">1 to 2 weeks</option>
                        <option value="2 to 4 weeks">2 to 4 weeks</option>
                        <option value="More than 4 weeks">More than 4 weeks</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.touched.recruitmentTimeline && formik.errors.recruitmentTimeline}
                      </Form.Control.Feedback>
                    </Form.Group>

                    {/* Education Level */}
                    <Form.Group controlId="educationLevel">
                      <Form.Label className="fw-bold   employers-lable">
                        Education Level *
                      </Form.Label>
                      <Form.Select 
                        value={formik.values.educationLevel}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        isInvalid={formik.touched.educationLevel && formik.errors.educationLevel}
                      >
                        <option value="">Select an option</option>
                        <option value="Master degree">Master degree</option>
                        <option value="Bachelor degree">Bachelor degree</option>
                        <option value="12th">12th</option>
                        <option value="10th">10th</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {formik.touched.educationLevel && formik.errors.educationLevel}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Row className=" justify-content-end mt-3">
                      <Col>
                        <Button
                          variant="primary"
                          type="submit"
                        >
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreateEmployersAccountFormThree;
