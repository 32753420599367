
import { Image } from "react-bootstrap";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link, NavLink } from "react-router-dom";
// import "../../../styles/EmployerJobPostListedPageSidebar.css";
import "../../src/styles/EmployerJobPostListedPageSidebar.css";
import NewLogo from "../assets/images/New Logo.svg"
import { useState } from "react";
import { CgHome } from "react-icons/cg";
import { FiPhone } from "react-icons/fi";
import { TbBrandCampaignmonitor } from "react-icons/tb";
import { HiOutlineUsers } from "react-icons/hi2";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { PiPencilSimple } from "react-icons/pi";
import { MdOutlineAnalytics } from "react-icons/md";
import { PiNotePencil } from "react-icons/pi";

const EmployerSidebar = (props) =>{
    
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [toggle,setToggle] = useState(1);

    const toggleSidebar = () => {
        setSidebarExpanded(!sidebarExpanded);
        const wrapper = document.getElementById("wrapper");
        wrapper.classList.toggle("toggled");
    };

    function ToggleBtn(num){
        setToggle(num);
    }

    // const {sidebarExpanded} = props
return(
    <aside id="sidebar-wrapper" className={sidebarExpanded ? "expand" : ""}>
        <div className="sidebar-brand d-flex justify-content-between align-items-center p-3 px-4">
            <div className="hov">
                <Link
                    to="#"
                    className="text-decoration-none fw-bold fs-4 text-dark icon-size-topbar"
                    onClick={toggleSidebar}
                >
                    <FontAwesomeIcon icon={faBars} id="sidebar" />{" "}
                </Link>
            </div>
            <Image src={NewLogo} fluid className="nav-logo2" />
            <div>
                
            </div>
        </div>
        
        {/* <ul className="sidebar-nav mt-2">
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 1 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/jobpostdashbord" className={ toggle === 1 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(1)}>
                    <CgHome className="fs-5" />
                    <span className="p-4 icon-type">Jobs</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 2 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 2 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(2)}>
                    <FiPhone className="fs-5" />
                    <span className="p-4 icon-type">Phone Calls</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 3 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 3 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(3)}>
                    <TbBrandCampaignmonitor className="fs-5" />
                    <span className="p-4 icon-type">Campaigns</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 4 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 4 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(4)}>
                    <HiOutlineUsers className="fs-5" />
                    <span className="p-4 icon-type">Candidate</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 5 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 5 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(5)}>
                    <TbBrandGoogleAnalytics className="fs-5" />
                    <span className="p-4 icon-type">Search Resume</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 6 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 6 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(6)}>
                    <PiPencilSimple className="fs-5" />
                    <span className="p-4 icon-type">Interview</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 7 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 7 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(7)}>
                    <MdOutlineAnalytics className="fs-5" />
                    <span className="p-4 icon-type">Analytics</span>
                </Link>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <div className={ toggle === 8 ? "active-tab-btn" : "tab-btn" }></div>
                <Link to="/employers/interviews" className={ toggle === 8 ? "icon-btn active-tab" : "icon-btn"} onClick={()=>ToggleBtn(8)}>
                    <PiNotePencil className="fs-5" />
                    <span className="p-4 icon-type">Tools</span>
                </Link>
            </li>
        </ul> */}

        <ul className="sidebar-nav mt-2">
            {/* <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/employers/jobpostdashbord" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <CgHome className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Jobs</span>
                </NavLink>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/employers/interviews" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <FiPhone className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Phone Calls</span>
                </NavLink>
            </li> */}


            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/employers/jobpostdashbord" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <CgHome className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Jobs</span>
                </NavLink>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/employers/interviews" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <PiPencilSimple className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Interview</span>
                </NavLink>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/It" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <FiPhone className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Phone Calls</span>
                </NavLink>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/contact" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <HiOutlineUsers className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Candidate</span>
                </NavLink>
            </li>
            <li className="p-1 d-flex justify-content-start gap-1">
                <NavLink to="/Feedback" className={({isActive})=> isActive ? "icon-btn active-tab" : "icon-btn"}>
                    <TbBrandGoogleAnalytics className="fs-5 mx-2" />
                    <span className="p-2 px-4 icon-type">Search Resume</span>
                </NavLink>
            </li>
        </ul>
    </aside>
)
}
export default EmployerSidebar;
