import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Card,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isObjectEmpty } from "../../helpers/utility";
import { getJobSeeker } from "../../helpers/backendHelper";
function ApplyJobReviewPage({ onContinue, jobApplyData, checked, onChange }) {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));
  const [userData, setUserData] = useState({
    firstName: "",
    email: "",
    mobileNumber: "",
    employerCity: "",
    employerCountry: "",
    employerArea: "",
    employerPincode: "",
    employerStreet: "",
    isTwoFAEnabled: "",
    isTwoFASetup: "",
    isProfileUpdate: false,
  });
  const handleContinue = (e) => {
    e.preventDefault();
    onContinue(userData);
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!isObjectEmpty(user)) {
        fetchUserData();
      }
    }, 2000);
    return () => clearTimeout(timerId);
  }, []);

  const fetchUserData = async () => {
    try {
      const response = await getJobSeeker();
      if (response && response.statusCode === 200) {
        const data = response.jobseeker;
        setUserData(data);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  console.log("user", user);

  return (
    <div>
      {/* <Container className="mt-2">
        {" "}
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6}>
            <Card>
              <Card.Body>
                <Row className="align-items-center">
                  <Col lg={1} xs={2} className="text-center">
                    <i className="bi bi-file-earmark-text fs-4"></i>
                  </Col>
                  <Col lg={11} xs={10}>
                    <p className="fw-bold  mb-1">SAP Vistex Consultant</p>
                    <p className="mb-1">
                      Denken Solutions India Private Limited - Remote
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container> */}
      {console.log("userData", userData)}
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6}>
            <div className="card p-2">
              <Row>
                <Col xs="auto">
                  <a
                    href="/"
                    className="btn btn-link text-decoration-none text-dark d-flex"
                  >
                    <i className="bi bi-arrow-left fs-5 mt-1"></i> Back
                  </a>
                </Col>
                <Col className="text-end">
                  <a
                    href="/"
                    className="btn btn-link text-decoration-none text-dark"
                  >
                    Save & Exit
                  </a>
                </Col>
              </Row>
              <Row className="p-2">
                <Col>
                  <ProgressBar now={100} />
                </Col>
              </Row>
              <Row className="p-2">
                <Col>
                  <p className="h5 fw-bold">Please review your application</p>
                </Col>
              </Row>
              <Row className="mt-2 p-2">
                <Col className="">
                  <p className="h6">Contact information</p>
                </Col>
                {/* <Col xs="auto">
                  <p className="fw-bold text-primary">
                    {" "}
                    <Link to="/">Edit</Link>{" "}
                  </p>
                </Col> */}
              </Row>
              <Row className="mt-1 p-2">
                <Col>
                  <Card className="">
                    <Card.Body>
                      {/* Email */}
                      <Row>
                        <Col>
                          <Form>
                            <Form.Group controlId="nameInput">
                              <Form.Label className="fw-bold">
                                Full name
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={userData.firstName}
                              />
                            </Form.Group>
                          </Form>
                        </Col>
                      </Row>
                    

                      <Row>
                        <Form>
                          <Form.Group controlId="emailInput">
                            <Form.Label className="fw-bold">Email</Form.Label>
                            <Form.Control type="email" value={userData.email} />
                          </Form.Group>
                        </Form>
                      </Row>
                      {/* <Row>
                        <Col>
                          <p className="fw-bold">{userData.email}</p>
                          <p>
                            To mitigate fraud, Walkupp may mask your email
                            address. If masked, the employer will see an address
                            like{" "}
                            <spam className="fw-bold">{userData.email}</spam>{" "}
                            Some employers, however, may still be able to unmask
                            and see your actual email address.
                          </p>
                        </Col>
                      </Row> */}
                    

                      <Row>
                        <Form>
                          <Form.Group controlId="cityInput">
                            <Form.Label className="fw-bold">
                              city,state
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value="chennai,Tamilnadu"
                            />
                          </Form.Group>
                        </Form>
                      </Row>
                 

                      <Row>
                        <Form>
                          <Form.Group controlId="numberInput">
                            <Form.Label className="fw-bold">
                              phoneNumber
                            </Form.Label>
                            <Form.Control
                              type="number"
                              value={userData.mobileNumber}
                            />
                          </Form.Group>
                        </Form>
                      </Row>
                      <hr/>
                      <Row>
                        <Col className="mt-2 fw-bold">
                          <p className="h6">CV</p>
                        </Col>
                        <Col xs="auto" className="mt-1">
                          <p className="fw-bold">
                            {" "}
                            <Link
                              to="/"
                              className="text-decoration-none text-dark"
                            >
                              Edit
                            </Link>{" "}
                          </p>
                        </Col>
                      </Row>

                      <Col>
                        <Card className="">
                          <Card.Body>
                            {/* Document uploaded related icons */}
                            <Row className="align-items-center">
                              <Col
                                lg={1}
                                xs={2}
                                className="text-center text-primary"
                              >
                                <i className="bi bi-file-earmark-text fs-4"></i>
                              </Col>
                              <Col lg={11} xs={10}>
                                <p className="mb-1 fw-bold text-primary">
                                  {/* {jobApplyData.file.name} */}
                                  {jobApplyData.file
                                    ? jobApplyData.file.name
                                    : "No file uploaded"}
                                </p>
                              </Col>
                              {/* <Col lg={11} xs={10}>
                          <p className="font-weight-bold text-primary mb-1">
                            Upload a CV
                          </p>
                          <p className="mb-1">
                            Accepted file types are PDF, DOCX, RTF or TXT.
                          </p>
                          {jobApplyData.file && (
                            <p className="text-muted mb-0">
                              File Name: {jobApplyData.file.name}
                            </p>
                          )}

                        </Col> */}
                            </Row>
                            
                          </Card.Body>
                        </Card>
                      </Col>
                      <hr />
              <Row className="mt-3">
                <Col className="">
                  <p className="h6">Relevant experience</p>
                </Col>
                <Col xs="auto">
                  <p className="fw-bold text-primary">
                    {" "}
                    <Link to="/" className="text-decoration-none text-dark">Edit</Link>{" "}
                  </p>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col>
                  <Card>
                    <Card.Body>
 
                      <Row className="align-items-center">
                        <Col>
                          <p className="mb-1 fw-bold ">Software Engineer</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          {" "}
                          <p className="mb-1  ">
                            Cognizant Technology Solutions
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <hr/>
              <Row className="mt-3">
                <Col className="">
                  <p className="h6">
                    Get email updates for the latest developer jobs in
                    Bengaluru, Karnataka
                  </p>
                </Col>
                
                <Col xs="auto  form-check form-switch">
                  <Form.Check
                    type="switch"
                    id="mySwitch"
                    name="darkmode"
                    checked={checked}
                    onChange={onChange}
                    label=""
                  />
                </Col>
              </Row> 
               <Row>
                <p>
                  By creating a job alert, you agree to our{" "}
                  <Link to="">Terms</Link>. You can change your consent
                  settings at any time by unsubscribing or as detailed in our
                  terms.
                </p>
              </Row>
             
              <hr/>
              <Row>
                <p>
                  By pressing apply: 1) you agree to our{" "}
                  <Link to="/">Terms, Cookie and Privacy Policies</Link> ; 2)
                  you consent to your application being transmitted to the
                  Employer (Indeed does not guarantee receipt), and processed
                  and analysed in accordance with its and Indeed's terms and
                  privacy policies; 3) you acknowledge that, when you submit
                  applications for jobs outside your country, it may involve you
                  sending your personal data to countries with lower levels of
                  data protection; and 4) you further acknowledge that we may
                  hide your contact information until this employer moves
                  forward with your application.
                  <Link to=""> What the employers sees</Link>
                </p>
              </Row>
              <hr />
              <Row>
                <p>
                  You're currently subscribed to receive calls or text messages
                  from employers you've applied to, and Indeed. You can change
                  this in <Link to=""> Privacy settings</Link>.
                </p>
              </Row>
               {/* Continue Button */}
               <Row className="mt-3">
                <Button
                  xs={12}
                  variant="primary"
                  type="submit"
                  className="mt-3 btn-color-two"
                  onClick={handleContinue}
                >
                  Submit Your Application
                </Button>
              </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>

              {/* <Row className="mt-3">
                <Col className="">
                  <p className="h6">Employer questions</p>
                </Col>
                <Col xs="auto">
                  <p className="fw-bold text-primary">
                    {" "}
                    <Link to="/">Edit</Link>{" "}
                  </p>
                </Col>
              </Row> */}
              {/* <Row className="mt-2">
                <Col>
                  <Card className="mb-3 shadow">
                    <Card.Body>
                      
                      <Row>
                        <Col>
                          <p>
                            What is the highest level of education you have
                            completed?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="fw-bold">Bachelor's</p>
                        </Col>
                      </Row>
                      <Row>
                        <hr />
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            How many years of SAP SD experience do you have?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="fw-bold">1</p>
                        </Col>
                      </Row>
                      <Row>
                        <hr />
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            How many years of SAP Vistex experience do you have?
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="fw-bold">1</p>
                        </Col>
                      </Row>
                      <Row>
                        <hr />
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            Please list 2-3 dates and time ranges that you could
                            do an interview.
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className="fw-bold">any time</p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row> */}
              
              
              {/* <Row className="mt-3">
                <Col className="">
                  <p className="h6">Supporting documents</p>
                </Col>
                <Col xs="auto">
                  <p className="fw-bold text-primary">
                    {" "}
                    <Link to="/">Add</Link>{" "}
                  </p>
                </Col>
              </Row> */}
              {/* <Row className="mt-2">
                <Col>
                  <Card className="shadow">
                    <Card.Body>
                      
                      <Row className="align-items-center">
                        <Col>
                          <p className="mb-1">
                            No cover letter or additional documents included
                            (optional)
                          </p>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
              </Row> */}
              {/* <hr /> */}
              
              

             
              <Row className="mt-1 text-center">
                <Col>
                  <p>
                    Having an issue with this application?
                    <Link to="">Tell us more</Link>{" "}
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ApplyJobReviewPage;
