import React from "react";
import {
  Container,
  Row,
  Col,
  ProgressBar,
  Card,
  Button,
  Form,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { RiAddLine } from "react-icons/ri";

import { BsPencil, BsTrash } from "react-icons/bs";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";
import "../../../styles/ThemeColors.css";

function CertificationLicencePage({ onContinue }) {
  const handleContinue = () => {
    onContinue();
  };

  return (
    <Container className="mt-5">
      {/* Center the form using Bootstrap grid */}
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          {/* Card-like styling */}
          <div className="card p-4">
            {/* Back Arrow and Save & Exit Link */}
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link">
                  <i className="bi bi-arrow-left"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link">
                  Save & Exit
                </a>
              </Col>
            </Row>

            {/* Progress Bar */}
            <Row className="mt-3">
              <Col>
                <ProgressBar animated now={75} label="75%" />
              </Col>
            </Row>

            {/* Title */}
            <Row className="mt-4">
              <Col>
                <p className="h4">
                  What certifications or licences do you have?
                </p>
              </Col>
            </Row>

            {/* Card view */}
            <Row className="mt-4">
              <Col>
                <Card>
                  <Card.Body className="position-relative">
                    {/* Edit and Delete Icons */}
                    <div
                      className="position-absolute top-0 end-0 m-3"
                      //   onClick={handleEditClick}
                    >
                      <BsPencil size={20} className="me-2" />
                      <BsTrash size={20} />
                    </div>
                    <Card.Title>Gcc licence</Card.Title>
                  </Card.Body>
                </Card>
                <Row className="mt-3">
                  <Form>
                    <Form.Group controlId="educationDetails">
                      <FormLabel className="ml-3 fw-bold">
                        Add a certifications or licences
                      </FormLabel>

                      <Row className="align-items-center">
                        <Col>
                          <Form.Control
                            type="text"
                            placeholder="Enter License Name"
                            className="ml-3"
                          />
                        </Col>
                        <Col xs="auto">
                          <Button variant="light" className="ml-1">
                            <RiAddLine />
                          </Button>
                        </Col>
                      </Row>
                    </Form.Group>
                  </Form>
                </Row>

                <Button
                  variant="primary"
                  type="submit"
                  className="mt-3 btn-colors"
                  onClick={handleContinue}
                >
                  Continue
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default CertificationLicencePage;
