// JobCardView.js
import React from "react";
import JobLists from "../helpers/JobLists";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Container from "react-bootstrap/Container";

function JobCardView() {
  return (
    <div>
      <Navbar />
      <Container className="mt-lg-4 mt-3">
        <JobLists />
      </Container>
      <Footer />
    </div>
  );
}

export default JobCardView;
