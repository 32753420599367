// import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoArrowBackSharp } from "react-icons/io5";
import { useLocation } from 'react-router-dom';
import withRouter from '../../components/common/withRouter';
import PinVerify from '../../components/common/PinVerify';
import { useSelector } from 'react-redux';
import { Card, Col, Container, Row, Image } from 'react-bootstrap';
import Navbar from "../../components/Navbar";
// import Footer from "../../components/footerAccessibilityPageFooter";
import Footer from "../../components/footerAccessibilityPageFooter"
import LoginImage from "../../assets/images/on-removebg-preview.svg";

const SignWithOtp = () => {

    const { existingUser, error, loading, errorMsg, email, mobileNumber } = useSelector(state => ({
        existingUser: state.Login.existingUser,
        error: state.Login.error,
        loading: state.Login.loading,
    }));
    const userType = existingUser && existingUser.userType ? existingUser.userType : null

    const navigate = useNavigate()
    const location = useLocation();

    let emailOrMobileNumber = {}
    if (location.state) {
        const { email, mobileNumber } = location.state;
        if (email) {
            emailOrMobileNumber.email = email;
        } else if (mobileNumber) {
            emailOrMobileNumber.mobileNumber = mobileNumber;
        } else {
            return null;
        }
    }
    const onComplete = () => {

        let path = ''
        if (userType == 'JobSeeker') {
            path = '/auth/home'
        } else if (userType == 'Employer') {
            const isTwoFaEnable = existingUser && existingUser.user.isTwoFAEnabled
            console.log('existingUser', existingUser)

            console.log('isTwoFaEnable', isTwoFaEnable)
            if (isTwoFaEnable) {
                path = '/auth/verify/2FA'
            }
            else {
                path = '/employers/jobpostdashbord'
            }

        } else {
            path = '/'
        }
        navigate(path);
    }
    return (
        <>
            <Navbar />
            <div class="d-flex align-items-center signup-bg">
                <Container className='mt-4'>
                    <Row className='reverse-col d-flex justify-content-between align-items-center'>
                        <Col md={6} lg={5} >
                            <Card className='signup-card text-dark'>
                                <h4><strong>Sign In With Login Code</strong></h4>
                                <p class="mt-2">We've sent your one-time passcode to <a href="www.gmail.com" className='text-color-dark'>{location.state.email}</a>. This passcode will expire after 5 minutes.</p>
                                <p class="mt-2"><strong>Enter 6-digit code*</strong></p>
                                <PinVerify
                                    className="border border-info"
                                    emailorMobileNumber={emailOrMobileNumber}
                                    type="oneTimeOtp"
                                    userType={userType}
                                    onComplete={onComplete}
                                />
                                {/* <p class="card-text text-secondary">Didn't receive your code ?</p>
                                    <Link class="text-decoration-none m-3"><strong>Send new code</strong></Link> */}
                                {/* <a href="#" class="btn btn-color-secondary"><strong>Signin<FaArrowRight /></strong></a> */}
                                {/* <Link class="text-decoration-none m-3"><strong>Don't have access to time email?</strong></Link> */}
                            </Card>
                        </Col>

                        <Col md={6} lg={5} className='d-none d-md-block'>
                            <Image className="login-img" src={LoginImage} fluid/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    )
}
export default withRouter(SignWithOtp);