import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { BsPeople, BsFileEarmarkText, BsStar } from "react-icons/bs";
import "../styles/ThemeColors.css";
import FirstImg from "../assets/images/job-img-1.jpg";
import SecondImg from "../assets/images/job-img-2.webp";
import ThirdImg from "../assets/images/job-img-3.jpg";

const AboutUs = () => {
  // Static counts for demo purposes
  const uniqueVisitorsCount = 50000;
  const resumesCount = 10000;
  const ratingsAndReviewsCount = 500;

  return (
    <Container className="mt-lg-5 mt-3">
      {/* Main About Us content */}
      <Row>
        <Col xs={12} md={6} className="mb-3">
          <h2>About Us</h2>
          <p className="text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            consectetur tellus ut neque pretium, ac dignissim odio posuere. Nunc
            vulputate, augue ac cursus sodales, turpis urna hendrerit erat, vel
            fringilla elit risus id libero.
          </p>
          <p className="text-justify">
            Fusce rhoncus, nisi eget dignissim gravida, urna enim luctus velit,
            nec sagittis lacus odio id quam. Proin sollicitudin tellus nec leo
            eleifend, nec consectetur nisi commodo.
          </p>
        </Col>
        <Col xs={12} md={6}>
          <img
            src={FirstImg}
            alt="WE ARE HIRING"
            className="img-fluid rounded"
          />
        </Col>
      </Row>
      <Row className="mt-lg-5 mt-3">
        {/* Unique monthly visitors */}
        <Col xs={12} md={4} className="mb-2">
          <BsPeople size={50} className="text-colors mb-3" />
          <h4>Unique Monthly Visitors</h4>
          <p>
            <strong className="text-colors">
              {uniqueVisitorsCount.toLocaleString()}+
            </strong>
          </p>
        </Col>

        {/* Resumes on our site */}
        <Col md={4} xs={12} className="mb-2">
          <BsFileEarmarkText size={50} className="text-colors mb-3" />
          <h4>Resumes on Our Site</h4>
          <p>
            <strong className="text-colors">
              {resumesCount.toLocaleString()}+
            </strong>
          </p>
        </Col>

        {/* Total ratings and reviews */}
        <Col md={4} xs={12}>
          <BsStar size={50} className="text-colors mb-3" />
          <h4>Total Ratings and Reviews</h4>
          <p>
            <strong className="text-colors">
              {ratingsAndReviewsCount.toLocaleString()}+
            </strong>
          </p>
        </Col>
      </Row>
      {/* Our Leadership */}
      <Row className="mt-lg-5 mt-2">
        <Col md={6}>
          <h2>Our Leadership</h2>
          <p className="text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            consectetur tellus ut neque pretium, ac dignissim odio posuere. Nunc
            vulputate, augue ac cursus sodales, turpis urna hendrerit erat, vel
            fringilla elit risus id libero.
          </p>
          <p className="text-justify">
            Fusce rhoncus, nisi eget dignissim gravida, urna enim luctus velit,
            nec sagittis lacus odio id quam. Proin sollicitudin tellus nec leo
            eleifend, nec consectetur nisi commodo.
          </p>
          <Col className="text-start mt-3 mb-3">
            <Button className="btn site-btn-color btn-colors">Meet Our Team</Button>
          </Col>
        </Col>
        {/* Image content on the side */}
        <Col md={6}>
          <img
            src={SecondImg}
            alt="WE ARE HIRING"
            className="img-fluid rounded"
          />
        </Col>
      </Row>

      {/* Our commitments */}
      <Row className="mt-lg-5 mt-3">
        <Col md={6}>
          <img
            src={ThirdImg}
            alt="WE ARE HIRING"
            className="img-fluid rounded"
          />
        </Col>
        <Col md={6}>
          <h2 className="mt-2">Our Commitments</h2>
          <p className="text-justify">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
            consectetur tellus ut neque pretium, ac dignissim odio posuere. Nunc
            vulputate, augue ac cursus sodales, turpis urna hendrerit erat, vel
            fringilla elit risus id libero.
          </p>
          <p className="text-justify">
            Fusce rhoncus, nisi eget dignissim gravida, urna enim luctus velit,
            nec sagittis lacus odio id quam. Proin sollicitudin tellus nec leo
            eleifend, nec consectetur nisi commodo.
          </p>
          <Col className="text-start mt-3 mb-3">
            <Button className="btn site-btn-color btn-colors">Learn More</Button>
          </Col>
        </Col>
        {/* Image content on the side */}
      </Row>
    </Container>
  );
};

export default AboutUs;
