import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Addresume from "../../pages/jobapplypages/Addresume";
import EmployersQuestionPage from "../../pages/jobapplypages/EmployersQuestionPage";
import JobReleventExperianceDetailsForm from "../../pages/jobapplypages/JobReleventExperianceDetailsForm";
import ApplyJobReviewPage from "../../pages/jobapplypages/ApplyJobReviewPage";
import ResponsePage from "../../pages/jobapplypages/ResponsePage";
import { applyJobsByJobSeeker } from "../../helpers/backendHelper";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import ViewJobs from "../ViewJobs";

function JobapplyHomePage() {

    let { jobId } = useParams();
  
     const { user } = useSelector(state => ({
      user: state.Login.user,
      }));
     
      
    const [step, setStep] = useState(1);
    const [jobApplyData,setJobApplyData] = useState();
    const handleContinue = async (data) => {
    setJobApplyData(data);
    const updatedApplyData = {...jobApplyData, ...data };
    setJobApplyData(updatedApplyData);
    if(step === 3){
       const formData = new FormData();
      formData.append('resume', jobApplyData.file); 
      formData.append('jobId',jobId);
      try{
        const applyData = await applyJobsByJobSeeker(formData);
         setStep(step + 1);
      }
      catch(e){
        console.log(e,'e')
      }
    }
    else{
      setStep(step + 1);
    }
  };

  useEffect(() => {
  }, [jobApplyData]);
  return (
    <div  style={{backgroundColor:"#F3F9FE"}}>
      <Navbar />
      <div>
        {step === 1 && <Addresume onContinue={handleContinue} />}
        {/* {step === 2 && <EmployersQuestionPage onContinue={handleContinue} />} */}
        {step === 2 && (
          <JobReleventExperianceDetailsForm onContinue={handleContinue} />
        )}
        {step === 3 && <ApplyJobReviewPage onContinue={handleContinue} jobApplyData={jobApplyData} />}
        {step === 4 && <ResponsePage jobApplyData={jobApplyData}/>}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default JobapplyHomePage;
