

//image url
export const IMAGE_HOST_URL = "http://103.235.105.108:3030"
export const EXISTINGUSER_CHECK = "/user/existingUserCheck";
export const POST_LOGIN = "/login";

export const JOBSEEKER_REG = "/jobSeeker/create-job-seekers";
export const EMPLOYER_REG = "/employer/createEmployer";
export const GET_JOB_SEEKERS  = "/jobSeeker/getJobSeekerById";


export const JOBSEEKER_LOGIN_WITH_PASSWORD = "/jobSeeker/login";
export const JOBSEEKER_LOGIN_OTP = "/jobSeeker/verify-otp";

export const VIEW_JOB_BY_JOBSEEKER = "/jobSeeker/getAppliedJobsByJobseeker";
export const JOBSEEKER_RESUME_UPDATE = "/jobSeekerResume/buildResume"
export const GET_JOBSEEKER_RESUME_DETAILS = "/jobSeekerResume/getResumeDetails"

export const JOBSEEKER_SEND_OTP = "/jobseeker/sendOtp" 

export const GET_ALL_JOBS = "/jobs/get-jobs"
export const GET_JOB_SUGGESTIONS = "/user/getJobSuggestions"

export const GET_JOB_BY_ID = "/jobs/get-job-byJobId"

export const SEARCH_JOBS = "/jobs/search-jobs"

export const GET_CITY_STATE = "/user/getCities-states"


export const APPLY_JOBS_BY_EMPLOYER = "/jobApplication/applyJobApplication"

export const GET_EMPLOYER = "/employer/getEmployerById"

export const JOBSEEKER_PROFILE_UPDATE = "/jobseeker/updateJobseekerProfile"

   //forgotPasswore
   export const FORGOT_PASSWORD = "/jobSeeker/requestPasswordReset"
   export const RESET_PASSWORD = "/jobSeeker/resetPassword"
 //employer

    //auth
    export const EMPLOYER_SEND_OTP ="/employer/employerSendOtp"
    export const EMPLOYER_VERIFY_OTP ="/employer/verifyOtp"
    export const EMPLOYER_LOGIN_WITH_PASSWORD = "/employer/employerLogin"
    export const EMPLOYER_UPDATE_PROFILE = "/employer/updateEmployerProfile"
    export const TWO_FA_SETUP = "/employer/twoFa/setup"
    export const VERIFY_TWO_FA = "/employer/twoFa/verify"
    //jobs
    export const POSTJOB_FOR_EMPLOYER = "/jobs/create-job"
    export const GET_JOBS_BY_EMPLOYER = "/jobs/get-Jobs-ById"
    export const UPDATE_JOBS_BY_EMPLOYER = "/jobs/update-job"

    //job application
    export const UPDAT_APPLICATION_BY_EMPLOYER = "/jobApplication/employer/updateApplication"
    export const GET_JOB_AAPLICATION_BY_JOBID = "/jobApplication/appliedJobsByEmloyerWithJobId"

    export const GET_JOB_APPLICATION_BY_EMPLOYERID = "/jobApplication/viewAppliedJobsByEmployer"

    //schduleInterview
    export const INTERVIEW_SCHEDULE_BY_EMPLOYER ="/interview/schedule"
