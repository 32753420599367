import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, signInWithPopup } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD8u0_j6g3OYudKwtVt5MPjW9NyVoznBbY",
  authDomain: "walkupp-95a51.firebaseapp.com",
  projectId: "walkupp-95a51",
  storageBucket: "walkupp-95a51.appspot.com",
  messagingSenderId: "957281818755",
  appId: "1:957281818755:web:4d82f4d51ef21d746923b6"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

const signInWithGoogle = () => {
  return signInWithPopup(auth, googleProvider);
};

const signInWithFacebook = () => {
  return signInWithPopup(auth, facebookProvider);
};

export { auth, signInWithGoogle, signInWithFacebook };
