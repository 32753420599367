import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { AiOutlineCheckCircle } from "react-icons/ai";

function ResponsePage({jobApplyData}) {

  const email = jobApplyData ? jobApplyData.email : null

  return (
    <Container className="mt-2">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="card p-4">
            <Row className="mt-2 ">
              <Col>
                <p className="h2 text-center mt-2">
                  Your application has been submitted!
                </p>
              </Col>
              
            </Row>
            <Row>
              <Col>
              <Col>
                <p className="h5 text-center mt-2">Keep track of your applications</p>
              </Col>
                <p className="text-center mt-2">
                  You will receive a status update in an email from Indeed
                  within a few weeks of submitting your application. In the
                  meantime, you can view and track all your applications in the
                  Indeed My jobs section at any time.
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-center">
                  <Link to="/myjobs">View your applications on My jobs</Link>
                </p>
              </Col>
            </Row>
            {/* Continue Button */}
            <Row className="mt-3">
              <Col>
                <Button
                  as={Link}
                  to="/jobs"
                  className="mt-3 form-control btn-color-two"
                >
                  Return to job search
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ResponsePage;
