import React, { useState } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faPhone,
  faMapMarkerAlt,
  faAngleRight,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap-icons/font/bootstrap-icons.css";
import BuildInResumePageFooter from "../../../components/BuildInResumePageFooter";

function BuildanResumeHomePage() {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const dummyData = {
    name: "test ",
    email: "test@example.com",
    phoneNumber: "+1234567890",
    location: "City, Country",
    logo: "am-logo.png", // Replace with your logo image path
  };
  // const handleContinue = () => {
  //   // Add logic to handle form submission or navigate to the next step
  //   // For now, let's log a message to the console
  //   console.log("Form submitted! Implement your logic here.");
  // };
  const cardStyle = {
    // border: `1px solid ${isHovered ? "blue" : "black"}`,
    padding: "10px",
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={8}>
          <div className="card p-4 border-0">
            <Row>
              <Col xs="auto">
                <h1 className="text-bold"> {dummyData.name}</h1>
              </Col>
              <Col className="text-end">
                <Image src={dummyData.logo} alt="user logo"></Image>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs="auto">
                <FontAwesomeIcon icon={faEnvelope} className="icon-col" />
              </Col>
              <Col xs="auto">{dummyData.email}</Col>
            </Row>
            <Row className="mt-2">
              <Col xs="auto">
                <FontAwesomeIcon icon={faPhone} className="icon-col" />
              </Col>
              <Col xs="auto">{dummyData.phoneNumber}</Col>
              <Col className="text-end">
                <a href="/">
                  {" "}
                  <FontAwesomeIcon
                    icon={faAngleRight}
                    className="icon-col"
                  />{" "}
                </a>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col xs="auto">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="icon-col" />
              </Col>
              <Col xs="auto">{dummyData.location}</Col>
            </Row>
            <Row className="mt-3">
              <Col xs="auto">
                <h4 className="text-bold">Resume</h4>
              </Col>
            </Row>
            <Row>
              <div
                style={cardStyle}
                className="card p-4 "
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Row>
                  <Col xs="auto">
                    <Image src="" alt="logo"></Image>
                  </Col>
                  <Col xs="auto">
                    <h5 className="text-bold">Resume</h5>
                  </Col>
                </Row>
                <Row>
                  <Col className="ms-5">
                    <p className="text-bold ">updated jan 9, 2024</p>
                  </Col>
                  <Col className="text-end">
                    <a href="/">
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className="icon-col"
                      />
                    </a>
                  </Col>
                </Row>
                <Row>
                  <Col className="ms-5">
                    <i>
                      <FontAwesomeIcon icon={faEyeSlash} />
                      Not Searchable{" "}
                    </i>
                  </Col>
                </Row>
              </div>
            </Row>
          </div>
        </Col>
      </Row>
      <div>
        <BuildInResumePageFooter />
      </div>
    </Container>
  );
}

export default BuildanResumeHomePage;
