import React, { useEffect, useState } from "react";
import { Nav, Container, Button } from "react-bootstrap";
import MyJobsSaved from "./MyjobsPage/MyJobsSaved";
import MyJobsApplied from "./MyjobsPage/MyJobsApplied";
import MyjobsInterviews from "./MyjobsPage/MyjobsInterviews";
import MyJobsArchived from "./MyjobsPage/MyJobsArchived";
import Navbar from "../../components/Navbar";
import FooterAccessibilityPageFooter from "../../components/footerAccessibilityPageFooter";
import { viewJobsByJobSeeker } from "../../helpers/backendHelper";

const Myjobs = () => {
  const [tab, setTab] = useState("applied");
  const [jobs, setJobs] = useState([]);
  const [viewAppliedStatus, setViewAppliedStatus] = useState("applied");

  useEffect(() => {
    fetchJobs();
  }, [viewAppliedStatus]);

  const fetchJobs = async () => {
    try {
      const inputData = {
        appliedStatus: viewAppliedStatus,
      };
      const response = await viewJobsByJobSeeker(inputData);
      if (response && response.statusCode === 200) {
        setJobs(response.appliedJobs.length > 0 ? response.appliedJobs : []);
      } else {
        setJobs([]);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const handleClick = (name) => {
    if (name !== tab) {
      setTab(name);
      setViewAppliedStatus(name);
    }
  };

  const showingDetails = () => {
    switch (tab) {
      case "applied":
        return <MyJobsApplied jobs={jobs} />;
      case "interview":
        return <MyjobsInterviews jobs={jobs} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <Navbar />
      <Container className="mt-3">
        <div className="row">
          <div className="col-12">
            <h1>
              <strong>My jobs</strong>
            </h1>
          </div>
          <div className="col-12 text-secondary">
            <p>
              Your Applied, Interviews Jobs will appear on this page.
            </p>
          </div>
          <Nav className="d-flex justify-content-start mt-lg-2">
            <Nav.Link onClick={() => handleClick("applied")}>
              <Button className="btn btn-rounded btn-light">Applied</Button>
            </Nav.Link>
            <Nav.Link onClick={() => handleClick("interview")} className="linkss">
              <Button className="btn btn-rounded btn-light">Interviews</Button>
            </Nav.Link>
          </Nav>
          <hr />
          {showingDetails()}
        </div>
      </Container>
      <FooterAccessibilityPageFooter />
    </div>
  );
};

export default Myjobs;
