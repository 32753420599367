import React, { useState } from 'react';
import { FaArrowRight } from "react-icons/fa";
import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";

import { Col, Container, Row, Button } from 'reactstrap';
import Swal from 'sweetalert2';

import { employerVerifyOtp, jobSeekerLoginApi, jobSeekerVerifyOtp, verify2FA } from '../../helpers/backendHelper';
import { empolyerLoginWithOtp, jobSeekerLoginWithOtp, loginWithOtp } from '../../slice/auth/login/thunk';
import "../../styles/Main.css";

const PinVerify = (props) => {

    const { emailorMobileNumber, type, onComplete, userType } = props;
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();
    let query = {}
    // const emailMobileNumber = emailorMobileNumber.email ? emailorMobileNumber.email : emailorMobileNumber.mobileNumber
    if (emailorMobileNumber.email) {
        query = { email: emailorMobileNumber.email, otp: otp, userType: userType }
    } else if (emailorMobileNumber.mobileNumber) {
        query = { mobileNumber: emailorMobileNumber.mobileNumber, otp: otp, userType: userType }
    }

    document.title = props.text;

    const handleConfirm = (e) => {

        e.preventDefault();
        if (type === "oneTimeOtp" && userType === "JobSeeker") {
            dispatch(jobSeekerLoginWithOtp(query))
                .then((x) => {
                     if (!x) {
                        Swal.fire({
                            icon: "error",
                            title: "OTP Error",
                            text: x.message ? x.message : null
                        });
                    } else {
                        onComplete();
                    }
                })
                .catch((e) => {
                    console.log('error', e);
                    Swal.fire({
                        icon: "error",
                        title: "Invalid PIN",
                        text: "Please enter the correct PINs",
                        showCancelButton: true,
                        showConfirmButton: false,
                        cancelButtonText: "Close"
                    }).then((x) => {
                        if (x.isDismissed) {
                            // onClose();
                        }
                    });
                })
                .finally(() => {
                    // setLoading(false);
                });
        }
        else if (type === "oneTimeOtp" && userType === "Employer") {
            dispatch(empolyerLoginWithOtp(query)).then((x) => {
                if (x && x.statusCode === 404) {
                    Swal.fire({
                        icon: "error",
                        title: "OTP Error",
                        text: x.message ? x.message : null
                    }).then(() => {
                       
                    });

                } else {
                    onComplete();
                }
            }).catch((e) => {
                console.log('error', e)
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                }).then((x) => {
                    if (x.isDismissed) {
                        // onClose();
                    }
                })
            }).finally(() => {
                // setLoading(false);
            })
        }
        else if (type == "twoFa" && userType == "Employer") {
            verify2FA({ code: otp }).then((x) => {
                Swal.fire('success!', '2fa verified successfully', 'success');
                onComplete();
            }).catch((e) => {
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                })
            })
        }
        else {
            jobSeekerVerifyOtp({ code: otp })
            .then((x) => {
                Swal.fire('verified!', 'otp verified successfully', 'success');
            }).catch((e) => {
                console.log('error', e)
                Swal.fire({
                    icon: "error",
                    title: "Invalid PIN",
                    text: "Please enter the correct PINs",
                    showCancelButton: true,
                    showConfirmButton: false,
                    cancelButtonText: "Close"
                }).then((x) => {
                    if (x.isDismissed) {
                        // onClose();
                    }
                })
            }).finally(() => {
                // setLoading(false);
            })
        }
    }

    return (
        <>
            <div className="auth-page-wrapper">
                <div className="auth-page-content">
                    <Container className='otp-position'>
                        <Row className="mt-4 d-flex justify-content-start align-items-center">
                            <Col sm={12}>
                                <div className="d-flex justify-content-start otp-position-content">
                                    <OTPInput
                                        value={otp}
                                        inputStyle="otp-input"
                                        onChange={setOtp}
                                        inputType='password'
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} className='otp-input' />}
                                    />
                                </div>
                            </Col>
                            <Col sm={12}>
                            <div className="d-flex justify-content-start otp-position-btn w-100">
                                {/* <a href="#" className="btn btn-primary ml-3" onClick={handleConfirm}><strong>{type == 'twoFa'?'verify':'SignIn'}<FaArrowRight /></strong></a> */}

                                {/* <a href="#" className="btn text-white ml-3" style={{backgroundColor:"#3A95CD"}} onClick={(e) => handleConfirm(e)}>
                                    <strong>{type == 'twoFa ' ? 'verify ' : 'SignIn '}<FaArrowRight /></strong>
                                </a> */}

                                <Button 
                                className="text-white border border-transparent otp-button" 
                                size='lg'
                                style={{ backgroundColor: "#3A95CD" }} 
                                onClick={(e) => handleConfirm(e)}
                                >
                                <strong>{type === 'twoFa' ? 'Verify ' : 'SignIn '}<FaArrowRight /></strong>
                                </Button>

                            </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
};

export default PinVerify;