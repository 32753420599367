import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ProgressBar,
  Card,
  Form,
} from "react-bootstrap";
// import { useNavigate } from "react-router-dom";

function JobReleventExperianceDetailsForm({ onContinue }) {
  // const Navigate=useNavigate();


  const [job, setJob] = useState("");
  const [title, setTitle] = useState("");

  const handleContinue = (e) => {
    e.preventDefault();
    const data  = { job, title };
    onContinue(data);
    // Navigate('/ApplyReview');
  };

  
  return (
    <Container className="mt-3">
      <Row className="justify-content-center">
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className="card p-3">
            <Row>
              <Col xs="auto">
                <a href="/" className="btn btn-link text-decoration-none d-flex text-dark gap-2">
                  <i className="bi bi-arrow-left fs-5 mt-1"></i> Back
                </a>
              </Col>
              <Col className="text-end">
                <a href="/" className="btn btn-link text-decoration-none text-dark">
                  Save & Exit
                </a>
              </Col>
            </Row>
            <Row className="mt-3 p-2">
              <Col>
                <ProgressBar now={60} />
              </Col>
            </Row>
            <Row className=" p-2">
              <Col>
                <p className="h5 fw-bold">Enter a job that shows relevant experience</p>
              </Col>
            </Row>
            <Row className="p-2">
              <p>
                <span className="fw-bold">Relevant experience</span>(optional)
              </p>
            </Row>
            <Row className="p-1">
              <Col>
                <Card>
                  <Card.Body>
                    <Form>
                      <Form.Group controlId="jobInput">
                        <Form.Label className="fw-bold">Job</Form.Label>
                        <Form.Control 
                          type="text" 
                          placeholder="Enter job" 
                          value={job} 
                          onChange={(e) => setJob(e.target.value)}  
                        />  
                    </Form.Group>
                      <Form.Group controlId="titleInput">
                        <Form.Label className="fw-bold">Title</Form.Label>
                        <Form.Control type="text"
                        placeholder="Enter title" 
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}  
                        />
                      </Form.Group>
                    </Form>
                  </Card.Body>
                </Card>
              </Col>
            </Row>

            {/* Continue Button */}
            <Row className="p-3">
              <Button
                xs={12}
                variant="primary"
                type="submit"
                className="p-1 btn-color-two"
                onClick={handleContinue}
              >
                Submit
              </Button>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default JobReleventExperianceDetailsForm;
