import SessionCheck from "../components/common/SessionCheck";
import FooterAccessibilityHomePage from "../footer-related-pages/footerAccessibilityHomePage";
import ForgetPassword from "../pages/Forgetpassword/ForgetPassword";
import Codepsw from '../pages/Forgetpassword/Emailotp';
import Editpswd from "../pages/Forgetpassword/Editpswd";
import About from "../pages/About";
import Home from "../pages/Home";
import Contact from "../pages/Footer/Contact";
import Feedback from "../pages/Footer/Feedback";
import ITJob from "../pages/Footer/ITJob";
import NonIt from '../pages/Footer/NonIt';
import Jobseeker from '../pages/Footer/Jobseeker';
import JobCardView from "../pages/JobCardView";
import Messages from "../pages/Messages";
import Notification from "../pages/Notification";
import SalaryGuidePage from "../pages/SalaryGuide";
import ViewJobs from "../pages/ViewJobs";
import Candidates from "../pages/employers/Candidates/Candidates";
import Collapsepageview from "../pages/employers/Collapsepageview";
import CreateEmployersAccountHomePage from "../pages/employers/CreateEmployersAccountHomePage";
import Interview from "../pages/employers/Interviews/Interview";
import ReviewEmployersAccount from "../pages/employers/ReviewEmployersAccount";
import WithoutSigninEmployerspage from "../pages/employers/WithoutSigninEmployerspage";
import EmployerJobPostListedPageSidebarMenu from "../pages/employers/employersjobpostdashboard/EmployerJobPostListedPageSidebarMenu";
import JobapplyHomePage from "../pages/jobapplypages/JobapplyHomePage";
import NewLogin from "../pages/login/NewLogin";
import NewLoginNext from "../pages/login/NewLoginNext";
import SignWithOtp from "../pages/login/SignWithOtp";
import SignWithPassword from "../pages/login/SignWithPassword";
import TwoFactorVerification from "../pages/login/TwoFactorVerification";
import CreateAccount from "../pages/login/createAccount";
import UserType from "../pages/login/userTypeSelection";
import BuildAnResumeHomePage from "../pages/profile/BuildAnResumeHomePage";
import AddressForm from "../pages/profile/BuildanResumePages/AddressForm";
import BuildanResumeHomePage from "../pages/profile/BuildanResumePages/BuildanResumeOverviewPage";
import EducationDetailsForm from "../pages/profile/BuildanResumePages/EducationDetailsForm";
import PhoneNumberForm from "../pages/profile/BuildanResumePages/PhoneNumberForm";
import ReviewEducation from "../pages/profile/BuildanResumePages/ReviewEducation";
import EmployerProfile from "../pages/profile/Employer";
import GetJobsByJobSeeker from "../pages/profile/GetJobsByJobSeeker";
import Helpcenter from "../pages/profile/Helpcenter";
import JobSeekerProfile from "../pages/profile/JobSeeker/jobSeekerProfile";
import MyReviews from "../pages/profile/MyReviews";
import Myjobs from "../pages/profile/Myjobs";
import Myprofile from "../pages/profile/Myprofile";
import NavbarSettingsSidebar from "../pages/settings/navbarSettingsSidebar";
import Testing from "../pages/testing";
import Mobilereg from "../pages/Forgetpassword/Mobilereg";
import Mobileotp from "../pages/Forgetpassword/Mobileotp";
import Signupoption from "../pages/login/SignupOptions";
import GoogleLogin from "../pages/login/GoogleLogin";

const authProtectedRoutes = [
  { path: "/employer/post", component: <ReviewEmployersAccount /> },
  {
    path: "/collapsepageview",
    component: <Collapsepageview name="Collapse" />,
  },
  {
    path: "/employers/jobpostdashbord",
    component: <EmployerJobPostListedPageSidebarMenu />,
  },
  {
    path: "/employer/employerProfile",
    component: <EmployerProfile />

  },
  { path: "auth/home", component: <Home /> },
  { path: "/auth/verify/2FA", component: <TwoFactorVerification /> },
  { path: "/employers/candidates/:id", component: <Candidates /> },
  { path: "/employers/interviews", component: <Interview /> },
  {path:"/sessionCheck",component:<SessionCheck />},
  //jobSeeker
  { path: "/jobSeeker/profile", component: <JobSeekerProfile /> }
];
const publicRoutes = [
  { path: "/", component: <Home /> },
  {path:"/GoogleLogin",component:<GoogleLogin />},
  // { path: "/auth/otp/signin", component: <Login /> },
  { path: "/auth/signup", component: <Signupoption />},
  { path: "signup", component: <NewLogin /> },  
  { path: "/auth/otp/signIn", component: <SignWithOtp /> },
  { path: "/auth/signIn", component: <SignWithPassword /> },
  { path: "/auth", component: <NewLoginNext /> },
  { path: "/auth/userType", component: <UserType /> },
  { path: "/auth/createAccount", component: <CreateAccount /> },
  {path:"/forgetPassword",component:<ForgetPassword/>},
  {path:'/forgetpassword/otp',component:<Codepsw/>},
  {path:'/mobile/otp',component:<Mobileotp/>},
  {path:'/editpassword',component:<Editpswd/>},
  {path:'/tryanotherway',component:<Mobilereg/>},
  { path: "/home", component: <Home /> },
  { path: "/about", component: <About /> },
  { path:"/contact", component: <Contact/> },
  {path:"/Feedback",component:<Feedback/>},
  {path:'/It',component:<ITJob/>},
  {path:'/nonIt',component:<NonIt/>},
  {path:'/jobseeker',component:<Jobseeker/>},
  { path: "/jobs", component: <ViewJobs /> },
  { path: "/job/:jobId/:isNavigateFromHome", component: <ViewJobs /> },
  { path: "/job/:jobTitle/:location/:isSearchFromHome", component: <ViewJobs /> },
  { path: "/sg", component: <SalaryGuidePage /> },
  { path: "/careerGuidepage", component: <careerGuidepage /> },
  { path: "/Accessiblility", component: <FooterAccessibilityHomePage /> },
  { path: "/Notification", component: <Notification /> },
  { path: "/myprofile", component: <Myprofile /> },
  { path: "/myjobs", component: <Myjobs /> },
  { path: "/myreviews", component: <MyReviews /> },
  { path: "/helpcenter", component: <Helpcenter /> },
  { path: "/message", component: <Messages /> },
  { path: "/settings", component: <NavbarSettingsSidebar /> },
  { path: "/employers", component: <WithoutSigninEmployerspage /> },
  { path: "/resume", component: <BuildAnResumeHomePage /> },
  { path: "/applyjob/:jobId", component: <JobapplyHomePage /> },
  { path: "/empformone", component: <CreateEmployersAccountHomePage /> },
  { path: "/resume/phoneNumber", component: <PhoneNumberForm /> },
  { path: "/resume/addressForm", component: <AddressForm /> },
  { path: "/resume/educationDetails", component: <EducationDetailsForm /> },
  { path: "/resume/educationReview", component: <ReviewEducation /> },
  { path: "/resume/resumeOverview", component: <BuildanResumeHomePage /> }
];
export { authProtectedRoutes, publicRoutes };

