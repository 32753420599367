


import React, { useEffect, useState } from "react";
import {
    Button,
    Col,
    Container,
    Form,
    Modal,
    Nav,
    Row,
    Tab,
    Image
} from "react-bootstrap";
import Swal from 'sweetalert2';
import CommonTable from "../../../components/common/CommonTable";
import { getJobApplicationByEmployerId, getJobApplicationDetailsByJobId, updateApplicationByEmployer } from "../../../helpers/backendHelper";
import { Link, useNavigate, useParams } from "react-router-dom";
import EmployerSidebar from "../../../components/EmployerSidebar";
import { useSelector } from "react-redux";
import { interviewConfrimSwalHelper, interviewSwalSuccessHelper } from "../../../helpers/interviewHelper";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";
import InterviewCard from "../../../helpers/Employers/Interviews/InterViewCard";
import Nodata from "../../../assets/images/no-data.webp";



const Interview = () => {
    const { user } = useSelector(state => ({
        user: state.Login.user,
    }));

    const navigate = useNavigate();

    const [applicationStatus, setApplicationStatus] = useState('scheduled');
    const [sidebarExpanded, setSidebarExpanded] = useState(false);
    const [applicationDetails, setApplicationDetails] = useState([])
    const fetchApplicationDetails = async () => {
        const applicationInputData = {
            employerId: user.uid,
            status: {
                status: applicationStatus
            }
        };
        getJobApplicationByEmployerId(applicationInputData)
            .then(data => {
                if (data && data.statusCode === 200 && data.appliedJobsData.length > 0) {
                    const appliedJobData = data.appliedJobsData.map(applicationData => {                        return {
                            id: applicationData.appliedJobs._id,
                            resumePath: applicationData.appliedJobs.resumePath,
                            jobTitle: applicationData.jobDetails.jobTitle,
                            jobStatus: applicationData.appliedJobs.status,
                            jobType: applicationData.jobDetails.jobType,
                            candidateName: applicationData.candidate.firstName,
                            email: applicationData.candidate.email,
                            mobileNumber: applicationData.candidate.mobileNumber,
                            appliedJobCount: applicationData.appliedJobCount,
                        };
                    });
                    setApplicationDetails(appliedJobData)
                } else {
                    setApplicationDetails([])
                }
            })
            .catch(error => {
                console.error("Error fetching jobs:", error);
            });
    };

    useEffect(() => {
        fetchApplicationDetails();
    }, [applicationStatus]);

    const TabColumn = ['Name', 'Email', 'JobTitle', 'JobStatus', 'MobileNumber', 'Resume', 'Resume', 'Action',];
    const statusOption = ['Hired', 'Reject']
    const upcomingInterviewOption = ['Hired', 'Reject']
    const hiredOption = ['Reject']
    const rejectOption = ['Hired']

    const OnStatusChange = async (status, row) => {
         let statusMsg = ""
        let data = {}
        if (status === "Hired") {
             statusMsg = "Hired"
            data = {
                inputData: {
                    appliedStatus :'interview',
                    status: 'hired',
                },
                id: row.id,
            };
        }
        else if (status === "Reject") {
            statusMsg = "rejected"
            data = {
                inputData: {
                    appliedStatus :'rejected',
                    status: 'rejected',
                },
                id: row.id,
            };
        }
        const confirmed = await interviewConfrimSwalHelper(statusMsg);
        if (confirmed.isConfirmed) {
            console.log('datainterview',data)
            const applicationUpdate = await updateApplicationByEmployer(data);
            if (applicationUpdate.statusCode === 200) {
                interviewSwalSuccessHelper(statusMsg)
            }
            fetchApplicationDetails();
        }
    }

    const handleNavClick = (status) => {
        setApplicationStatus(status)
    }

    const closeSidebar = (event) => {
        if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
            setSidebarExpanded(false);
            const wrapper = document.getElementById("wrapper");
            wrapper.classList.remove("toggled");
        }
    };
    return (<>
        <div id="wrapper" onClick={closeSidebar}>
            <EmployerSidebar sidebarExpanded={sidebarExpanded} />
            <div id="navbar-wrapper">
                <EmployerNavBar />
            </div>
            
            <section id="content-wrapper">
                <Container>
                    <Row className="justify-content-between">
                        <Col sm={3} xs={6}>
                            <h3>InterviewDetails</h3>
                        </Col>
                        <Col sm={3} xs={6} className="text-end">
                            <Link to="/empformone">
                                <Button variant="primary">Post Jobs</Button>
                            </Link>
                        </Col>
                    </Row>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="scheduled">
                        <Row className="mt-3">
                            <Col md={12} sm={6}>
                                <Nav variant="pills" className="flex-row">
                                    <Nav.Item>
                                        <Nav.Link eventKey='scheduled' onClick={() => handleNavClick('scheduled')}>
                                            Upcoming Interview
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey='hired' onClick={() => handleNavClick('hired')}>
                                            Hired Candidates
                                        </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey='rejected' onClick={() => handleNavClick('rejected')}>
                                            Rejected Candidates
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>
                        </Row>

                        <Row className=" mt-3 p-3 bg-white rounded">
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="scheduled">

                                        {applicationDetails.length > 0 ? (
                                            <InterviewCard columns={TabColumn}
                                            data={applicationDetails}
                                            statusOption={upcomingInterviewOption}
                                            OnStatusChange={OnStatusChange}
                                            tab={'scheduled'}
                                            type={'interview'}
                                        />
                                        ) : (<Image className="nodata-img" src={Nodata}/>)}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="hired">
                                        {applicationDetails.length > 0 ? (
                                            <InterviewCard columns={TabColumn}
                                            data={applicationDetails}
                                            statusOption={hiredOption}
                                            OnStatusChange={OnStatusChange}
                                            tab={'hired'}
                                            type={'interview'}
                                            />
                                        ) 
                                       : (<Image className="nodata-img" src={Nodata}/>)}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="rejected">

                                        {applicationDetails.length > 0 ? (
                                            <InterviewCard columns={TabColumn}
                                            data={applicationDetails}
                                            statusOption={rejectOption}
                                            OnStatusChange={OnStatusChange}
                                            tab={'rejected'}
                                            type={'interview'}
                                            />
                                        ) : (<Image className="nodata-img" src={Nodata}/>)}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>

                    </Tab.Container>
                </Container>
            </section>
        </div>

    </>)
}

export default Interview;
