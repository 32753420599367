import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Image from 'react-bootstrap/Image';
import { useSelector } from "react-redux";
import "../assets/css/home.css";
import job from '../assets/images/job.png';
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import MultiCarousel from "../components/common/MultiCarousel";
import { getAllJobs, getCityState, getJobSeeker, getJobSuggestions, searchJobs } from "../helpers/backendHelper";
import { isObjectEmpty } from "../helpers/utility";
import "../styles/Main.css";
import HomeDesign from "../helpers/HomeDesign/HomeDesign";
import StudyWomen from "../assets/images/women svg.svg";
import { Link, useNavigate } from "react-router-dom";



const Home = () => {

  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const navigate = useNavigate();
  const [jobs, setJobs] = useState([]);
  const [jobTitle, setJobTitle] = useState();
  const [activeIndex, setActiveIndex] = useState(0);
  const [jobSeeker, setJobSeeker] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [location, setLocation] = useState("");
  const [latestJobs, setLatestJobs] = useState([]);
  const [allCityState, setAllCityState] = useState([]);
  const [jobSuggestions, setJobSuggestions] = useState([]);
  const authToken = localStorage.getItem("authUser");

  useEffect(() => {
     const handlePopState = (event) => {
      if (authToken) {
         event.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      }
    };
    window.addEventListener('popstate', handlePopState);
     if (authToken) {
      window.history.pushState(null, null, window.location.pathname);
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [authToken]);
   
  useEffect(() => {
    fetchJobs();
    const intervalId = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % jobs.length);
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    fetchCityState();
    fetchJobSuggestion();
  }, []);

  const fetchCityState = async () => {
    try {
      const response = await getCityState();
      if (response && response.statusCode === 200) {
        const data = response.cities;
        setAllCityState(data);
      }
    } catch (error) {
      console.error("Error fetching city/state data:", error);
    }
  };

  const formik = useFormik({
    initialValues: {
      jobTitle: '',
      location: ''
    },
    validationSchema: Yup.object({
      jobTitle: Yup.string()
        .required('Job title is required'),
      location: Yup.string()
        .required('Location is required')
    }),
    onSubmit: values => {
      navigate(`/job/${values.jobTitle}/${values.location}/${true}`);
    }
  });

  const filteredCities = allCityState.filter(cityState =>
    cityState.city.toLowerCase().includes(formik.values.location.toLowerCase())
  );


  useEffect(() => {
    const timerId = setTimeout(() => {
      if (!isObjectEmpty(user)) {
        fetchUser();
      }
    }, 2000);
    return () => clearTimeout(timerId);
  }, [jobSeeker]);

  const fetchJobs = async () => {
    try {
      const response = await getAllJobs();
      if (response && response.statusCode === 200) {
        if (response.jobs.length > 0) {
          setJobs(response.jobs);
          setLatestJobs(response.jobs.slice(-3));
        }
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const fetchJobSuggestion = async () => {
    try {
      const response = await getJobSuggestions();
      if (response && response.statusCode === 200) {
        const data = response.jobTitle.map(job => `${job.name}`);
        setJobSuggestions(data);
      }
    } catch (e) {
      console.error("Error fetching job data:", e);
    }
  }

  const fetchUser = async () => {
    try {
      const response = await getJobSeeker();
      console.log('response user', response)
      if (response && response.statusCode === 200) {
        if (response.jobSeeker)
          setJobSeeker(response.jobSeeker)
      }

    } catch (e) {
      console.log("err", e)
    }
  }
  const handleSearch = (e) => {
    e.preventDefault();
    navigate(`/job/${jobTitle}/${location}/${true}`);
  };


  return (
    <div className="bg-white">
      <Navbar jobSeeker={jobSeeker} />
       <div className="slider-area">
        <div className="inside-slider container d-flex align-items-center">
          <Container className="slider-content">
            <Row>
              <Col lg="8">
                <Card className="bg-transparent border-0">
                  <h1 className="fw-bold mt-5 text-black">
                    Find freelance and fulltime <br /> developer jobs.
                  </h1>
                  <p className="text-black">
                    Glumos is your one-stop-centre for thousands of digital <br />{" "}
                    freelance and fulltime jobs.
                  </p>
                </Card>

                <Card className="mt-4 shadow border-0">
                  <Card.Body>
                    <div className="row g-3">
                      <div className="col-md-5 col-12">
                        <Form.Select
                          aria-label="jobTitle"
                          value={jobTitle}
                          onChange={(e) => setJobTitle(e.target.value)}
                          className="w-100"
                        >
                          <option value="">Select jobTitle</option>
                          {jobSuggestions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="col-md-5 col-12">
                        <Form.Select
                          aria-label="Location"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                          className="w-100"
                        >
                          <option value="">Select location</option>
                          {filteredCities.map((cityState, index) => (
                            <option key={index} value={cityState.city}>
                              {cityState.city}, {cityState.state}
                            </option>
                          ))}
                        </Form.Select>
                      </div>
                      <div className="col-md-2 col-12">
                        <Button
                          className="w-100 home-btn btn-color-primary"
                          type="button"
                          onClick={handleSearch}
                        >
                          Search
                        </Button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>


              </Col>
            </Row>
          </Container>
          <Image className="study-women d-none d-md-block" src={StudyWomen} />
        </div>
      </div>
      <div>
        <Row className="justify-content-center align-items-center h-100">
          <Col>
            <h2 className="text-center text-white">Recent Jobs</h2>
          </Col>
        </Row>
        <Container>
          <Row className="p-2">
            <Col className="mb-5">
              <h3 className="fw-bolder text-center">Job of the day</h3>
              <p className="text-center">Find the job that is perfect for you, About 800+ new jobs Everyday</p>
            </Col>
            <MultiCarousel jobs={jobs} />
          </Row>
        </Container>
      </div>

      <HomeDesign />
      {/* <Container>
        <Row className="mt-2">
          <Col lg={8} className="d-flex align-items-center">
            <h2 className="fw-bold">
              Life can only be understood<br />
              backward, but it must be lived <br />
              forwards
            </h2>
          </Col>
          <Col lg={4}>
            <Image src={job} />
          </Col>
        </Row>
      </Container> */}



      {/* <Container>
        <Row className="p-2">
          <JobList jobs={jobs} isLoggedIn={authToken} isSearch={isSearch} />
        </Row>
      </Container> */}
      <Footer />

    </div>
  );
};

export default Home;
