import React from "react";
import Navbar from "../components/Navbar";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "../styles/ThemeColors.css";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";

const Messages = () => {

  const { user } = useSelector(state => ({
    user: state.Login.user,
    }));
  return (
    <div>
      <Navbar />
      <div className="container mt-lg-4 mt-3">
        <Row className="d-flex justify-content-center">
            <Col md={6} xs={12} >
            <Card className="border-0">
         <Card.Body>
          <Row className="d-flex justify-content-center">
            <Col xs={12} md={6} lg={6}>
            <Card.Img variant="top" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSHuIIAl1ylLYoog8r7Cg9W5zWht9MzadeZMg&usqp=CAU" className="img-fluid mx-auto d-block" />
            </Col>
          </Row>
           <Card.Title className="text-center">
            <strong>Welcome to Messages</strong>
            </Card.Title>
          <p className="text-center">
            When an employer contacts you, you will see messages here.
          </p>
          <div className="text-center">
          <a href="/jobs" className="btn btn-colors m-2">
            Find jobs
          </a>
          <a href="#" className="btn btn-outline-colors m-2">
            Upload your CV
          </a>
          </div>
          </Card.Body>
        </Card>
        </Col>      
        </Row>
      </div>
    </div>
  );
};

export default Messages;
