import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import * as Yup from 'yup';
import { getCityState, getJobSuggestions } from "../../helpers/backendHelper";
import EmployerNavBar from "../../components/Employer/EmployerNavbar";
import EmployerSidebar from "../../components/EmployerSidebar";
import useFormikValidation from "../../helpers/FormikValidation";

function CreateEmployersAccountFormTwo({ onContinue }) {
  const validationSchema = Yup.object().shape({
    jobTitle: Yup.string().required('Job title is required'),
    city: Yup.string().required('City is required'),
    area: Yup.string().required('Area is required'),
    pincode: Yup.string().required('Pincode is required'),
    streetAddress: Yup.string().required('Street address is required'),
  });

  const onSubmit = (values, { setSubmitting }) => {
     
    onContinue('', {
      jobBasics: {
        jobTitle: values.jobTitle,
        jobLocation: {
          city: values.city,
          area: values.area,
          pincode: values.pincode,
          street: values.streetAddress,
        },
      },
    });
    setSubmitting(false);
  };

  const { formik, renderFormField } = useFormikValidation(
    {
      jobTitle: '',
      locationType: '',
      city: '',
      area: '',
      pincode: '',
      streetAddress: '',
    },
    validationSchema,
    onSubmit
  );

  useEffect(() => {
    fetchCityState();
    fetchJobSuggestion();
  }, []);

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [formData, setFormData] = useState({
    jobTitle: "",
    locationType: "",
    city: "",
    area: "",
    pincode: "",
    streetAddress: "",
  });
  const [cityOptions, setCityOptions] = useState([]);
  const [suggestionOptions, setSuggestionsOption] = useState([]);

  const fetchCityState = async () => {
    try {
      const response = await getCityState();
      if (response && response.statusCode === 200) {
        const data = response.cities.map(city => `${city.city}, ${city.state}`);
        setCityOptions(data);
      }
    } catch (error) {
      console.error("Error fetching city/state data:", error);
    }
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const fetchJobSuggestion = async () => {
    try {
      const response = await getJobSuggestions();
      if (response && response.statusCode === 200) {
        const data = response.jobTitle.map(job => `${job.name}`);
        setSuggestionsOption(data);
      }
    } catch (e) {
      console.error("Error fetching job data:", e);
    }
  }

  return (
    <div id="wrapper" onClick={closeSidebar}>
       <EmployerSidebar sidebarExpanded={sidebarExpanded} />
      <div id="navbar-wrapper">
        <EmployerNavBar />
      </div>
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={8} lg={8} className="p-0">
            <Card className="p-lg-5 p-3 bg-light border-0 rounded">
              <Row>
                <Col>
                  <h4>Add job basics</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                {console.log('suggestionOptions',suggestionOptions)}
                  <Form onSubmit={formik.handleSubmit}>

                     {renderFormField(
                      "jobTitle",
                      "Job title *",
                      "Select",
                      "select",
                      suggestionOptions
                    )}
                    {renderFormField(
                      "city",
                      "City, State *",
                      "Select",
                      "select",
                      cityOptions
                    )}
                    {renderFormField("area", "Area *", "", "text")}
                    {renderFormField("pincode", "Pincode *", "", "nu")}
                    {renderFormField(
                      "streetAddress",
                      "Street address *",
                      "",
                      "text"
                    )}

                    <Row className="mt-3 justify-content-end">
                      <Col>
                        <Button variant="primary" type="submit">
                          Continue
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default CreateEmployersAccountFormTwo;
