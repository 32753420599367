import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  Card,
  Form,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { employerPostJob } from "../../helpers/backendHelper";
import Swal from "sweetalert2";

function ReviewEmployersAccount({ jobInputData, show, handleClose }) {
  const navigate = useNavigate();

   const [showModal, setShowModal] = useState(false);
  const [editableField, setEditableField] = useState("");

  const handlePencilClick = (field) => {
    setShowModal(true);
    setEditableField(field);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setEditableField("");
  };

  const [formData, setFormData] = useState({
    jobTitle: jobInputData.jobBasics.jobTitle,
    // companyName: "Test Company",
    jobType:jobInputData.jobDetails.jobType,
    location: jobInputData.jobBasics.jobLocation.city,
    // pay: "10,00,000.00 per year",
    // supplementalPay: ["Performance Bonus", "Yearly Bonus"],
    // benefits: ["Health Insurance", "Work from Home"],
    jobDescription: <div dangerouslySetInnerHTML={{ __html: jobInputData.jobDescription }} />
  });

  const handleSaveChanges = () => {
    handleCloseModal();
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleClick = async () => {
    employerPostJob(jobInputData)
      .then((x) => {
        navigate("/employers/jobpostdashbord");
      })
      .catch((e) => {
        console.log("error", e);
        Swal.fire({
          icon: "error",
          title: "Failed to Job Post",
          text: "Error accured",
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: "Close",
        }).then((x) => {
          if (x.isDismissed) {
            // onClose();
          }
        });
      })
      .finally(() => {
        // setLoading(false);
      });
  };

  return (
    <div>
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} sm={12} md={12} lg={6} className="p-0">
            <Card className="p-lg-5 p-3 bg-light border-0 rounded">
              <Row>
                <Col>
                     <h3>Review</h3>
                </Col>
              </Row>
              {Object.keys(formData).map((key, index) => (
                <Row className="mt-2" key={index}>
                  <Col>
                    <h6 className="fw-bold text-secondary reviewpage-text-size">
                      {key.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </h6>
                  </Col>
                  <Col>
                    <p className="reviewpage-text-size">{formData[key]}</p>
                  </Col>
                  {/* <Col xs="auto">
                    <FontAwesomeIcon
                      icon={faPencilAlt}
                      className="ml-2 reviewpage-text-size"
                      onClick={() => handlePencilClick(key)}
                      style={{ cursor: "pointer" }}
                    />
                  </Col> */}
                </Row>
              ))}
              <Row className="mt-2">
                <Col xs="auto">
                  <Button
                    as={Link}
                    variant="primary"
                    type="submit"
                    className="ml-2"
                    onClick={handleClick}
                  >
                    Continue
                  </Button>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Edit {editableField.replace(/([a-z])([A-Z])/g, "$1 $2")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId={`form${editableField}`}>
              <Form.Label>
                {editableField.replace(/([a-z])([A-Z])/g, "$1 $2")}
              </Form.Label>
              <Form.Control
                type="text"
                name={editableField}
                value={formData[editableField]}
                onChange={handleInputChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReviewEmployersAccount;
