import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Image,
  Nav,
  Row,
  Tab,
  Form,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Job_image from "../../../assets/images/job_image.png";
import EmployerSidebar from "../../../components/EmployerSidebar";
import { getJobsByEmployer, updateJobByEmployer, fetchJobTitle, getJobSuggestions } from "../../../helpers/backendHelper";
import "../../../styles/EmployerJobPostListedPageSidebar.css";
import Swal from "sweetalert2";
import icon9 from "../../../assets/images/icon-9.jpg";
import EmployerNavBar from "../../../components/Employer/EmployerNavbar";

function EmployerJobPostListedPageSidebarMenu() {
  const { user } = useSelector((state) => ({
    user: state.Login.user,
  }));

  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [subModeJob, setSubModeJob] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [jobStatus, setJobStatus] = useState("opened");
  const [sortOrder, setSortOrder] = useState("descending");
  const [searchTitle, setSearchTitle] = useState("");
  const [jobTitles, setJobTitles] = useState([]);

  const fetchJobs = async (title = "") => {
    try {
      const data = { status: jobStatus, jobTitle: title };
      const response = await getJobsByEmployer(data);

      if (response && response.statusCode === 200) {
        if (response.jobs.length > 0) {
          setSubModeJob(true);
          setJobs(response.jobs);
        }
      } else {
        setSubModeJob(false);
        setJobs([]);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  const fetchJobTitles = async () => {
    try {
      const response = await getJobSuggestions();

      if (response && response.statusCode === 200) {
        console.log('response of getjobsuggestion', response)
        const data = response.jobTitle.map(job => `${job.name}`);
        setJobTitles(data);
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  useEffect(() => {
    fetchJobTitles();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      fetchJobs(searchTitle);
    }, 100);
    return () => clearTimeout(timer);
  }, [jobStatus, searchTitle]);

  const sortJobs = (jobs) => {
    return [...jobs].sort((a, b) => {
      if (sortOrder === "ascending") {
        return new Date(a.createdAt) - new Date(b.createdAt);
      } else if (sortOrder === "descending") {
        return new Date(b.createdAt) - new Date(a.createdAt);
      }
      return 0;
    });
  };

  const closeSidebar = (event) => {
    if (!event.target.closest("#sidebar-wrapper") && sidebarExpanded) {
      setSidebarExpanded(false);
      const wrapper = document.getElementById("wrapper");
      wrapper.classList.remove("toggled");
    }
  };

  const handleNavClick = (status) => {
    setJobStatus(status);
  };

  const handleDropdownToggle = async (jobId) => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "Are you sure you want to close this job?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, close it!",
    });
    if (confirmed.isConfirmed) {
      try {
        const data = {
          inputData: { status: "closed" },
          id: jobId,
        };
        const jobUpdate = await updateJobByEmployer(data);
        if (jobUpdate.statusCode === 200) {
          Swal.fire({
            title: "Success",
            text: "Job closed successfully!",
            icon: "success",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          });
          fetchJobs(searchTitle);
        }
      } catch (error) {
        console.error("Error closing job:", error);
      }
    }
  };

  const handleTitleSelect = (title) => {
    setSearchTitle(title);
  };

  return (
    <div id="wrapper" onClick={closeSidebar}>
      <EmployerSidebar sidebarExpanded={sidebarExpanded} />

        <div id="navbar-wrapper">
          <EmployerNavBar />
        </div>

        <section id="content-wrapper">
          <Container>
            <Row className="justify-content-between">
              <Col sm={6} xs={3}>
                <h4>Jobs</h4>
              </Col>
              <Col sm={6} xs={9} className="text-end">
                <Link to="/empformone">
                  <Button variant="primary">Post Jobs</Button>
                </Link>
              </Col>
            </Row>
            
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Row className="mt-3">
              <Col xs={8} sm={12}>
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item className="open-closed-btn rounded me-sm-3 my-2 my-sm-0">
                      <Nav.Link
                        eventKey="first"
                        onClick={() => handleNavClick("opened")}
                      >
                        Active
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="open-closed-btn rounded my-2 my-sm-0">
                      <Nav.Link
                        eventKey="second"
                        onClick={() => handleNavClick("closed")}
                      >
                        Closed{" "}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
              </Row>
              <Row className=" mt-3 p-3 bg-white rounded">
                <Col sm={12}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <Row className="d-flex justify-content-between">
                        <Col lg={4} className="mb-2">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              variant="white"
                              className="p-2 w-100 border-0 box-shadow text-truncate"
                            >
                              <span>
                                <Image src={icon9} height="23" />
                              </span>{" "}
                              {searchTitle || "Filter Period"}{" "}
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="ms-1"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("ascending")}>
                                Filter Period 1
                              </Dropdown.Item>
                              <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("descending")}>
                                Filter Period 2
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>

                        <Col lg={4} className="mb-2">
                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              variant="white"
                              className="p-2 w-100 border-0 box-shadow text-truncate"
                            >
                              <span>
                                <Image src={icon9} height="23" />
                              </span>{" "}
                              Sort by: Posting date{" "}
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="ms-1"
                              />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="w-100">
                              <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("ascending")}>
                                Posting Date (Ascending)
                              </Dropdown.Item>
                              <Dropdown.Item className="text-truncate" onClick={() => setSortOrder("descending")}>
                                Posting Date (Descending)
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>

                        <Col lg={4} className="mb-2">

                          <Dropdown>
                            <Dropdown.Toggle
                              id="dropdown-basic"
                              variant="white"
                              className="p-2 w-100 border-0 box-shadow text-truncate"
                            >
                              <span>
                                <Image src={icon9} height="23" />
                              </span>{" "}
                              {searchTitle || "Select Job Title"}{" "}
                              <FontAwesomeIcon
                                icon={faAngleDown}
                                className="ms-1"
                              />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="select-job-scroll">
                              {jobTitles.map((title, index) => (
                                <Dropdown.Item
                                  key={index}
                                  onClick={() => handleTitleSelect(title)}
                                >
                                  {title}
                                </Dropdown.Item>
                              ))}
                            </Dropdown.Menu>
                          </Dropdown>
                        </Col>
                          
                      </Row>
                      {!subModeJob ? (
                        <Row className="mt-5">
                          <Col sm={12}>
                            <Image
                              src={Job_image}
                              className="mx-auto d-block img-fluid mb-3"
                            />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {sortJobs(jobs).map((job, index) => (
                            <Row
                              key={index}
                              className="bg-job-list rounded mb-3 p-4 p-lg-1 mt-3"
                            >
                              <Col xs={2} sm={1} className="d-flex justify-content-center align-items-center">
                                <Form.Group className="" controlId="formBasicCheckbox">
                                  <Form.Check type="checkbox" />
                                </Form.Group>
                              </Col>
                              
                              <Col xs={10} sm={11} className="py-3">
                                <Row>
                                  <Col lg={2}>
                                    <Row>
                                      <Col xs={12} className="text-center">
                                        <h6 className="p-1 p-lg-0 cardtext">
                                          {job.jobBasics.jobTitle}
                                        </h6>
                                      </Col>
                                      <Col xs={12} className="text-center">
                                        <p className="cardtext p-1 p-lg-0">
                                          {job.jobBasics.jobLocation.city}
                                        </p>
                                      </Col>
                                      <Col xs={12} className="text-center">
                                        <p className="cardtext p-1 p-lg-0">{job.jobBasics.jobLocation.pincode}</p>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col lg={2} className="text-left-mobile-view p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    <Link to={`/employers/candidates/${job._id}`} className="text-dark text-decoration-none">
                                      <p className="fw-bold cardtext"><span>{job.appliedJobCount}</span>Applicants</p>
                                    </Link>
                                  </Col>
                                  <Col lg={2} className="text-left-mobile-view p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    <Link to={`/${job._id}`} className="text-dark text-decoration-none">
                                      <p className="fw-bold cardtext"><span className="fw-bold">1</span>Waiting</p>
                                    </Link>
                                  </Col>
                                  <Col lg={2} className="text-left-mobile-view p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    <Link to={`/${job._id}`} className="text-dark text-decoration-none">
                                      <p className="fw-bold cardtext"><span>1</span>Contact</p>
                                    </Link>
                                  </Col>
                                  <Col lg={2} className="p-1 p-lg-1 d-flex justify-content-center align-items-center">
                                    <p className="fw-bold cardtext"><span>{job.hiredCount} of{" "}
                                      {job.jobDetails.numberOfPositions}</span>Hired</p>
                                  </Col>
                                  <Col
                                    lg={2}
                                    className="text-center-mobile-view d-flex justify-content-center align-items-center"
                                  >
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="success"
                                        id="dropdown-basic"
                                        className="cardtext px-3"
                                        align={{ lg: 'end' }}
                                      >
                                        <span className="dot"></span>Action
                                        <span className="arrow-down"></span>
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDropdownToggle(job._id)
                                          }
                                        >
                                          Close Job
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                          
                                  </Col>

                                </Row>
                              </Col>

                            </Row>
                          ))}
                        </>
                      )}
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {!subModeJob ? (
                        <Row className="mt-5">
                          <Col sm={12}>
                            <Image
                              src={Job_image}
                              className="mx-auto d-block img-fluid mb-3"
                            />
                          </Col>
                        </Row>
                      ) : (
                        <>
                          {sortJobs(jobs).map((job, index) => (
                            <Row
                              key={index}
                              className="bg-job-list rounded mb-3 pt-1 pb-1 mt-3"
                            >
                              <Col sm={6}>
                                <Row className="d-flex align-items-center">
                                  <Col
                                    sm={2}
                                    xs={12}
                                    className="mx-auto text-center text-left-mobile-view"
                                  ></Col>
                                  <Col sm={6} className="text-start">
                                    <h5 className="mt-2">
                                      {job.jobBasics.jobTitle}
                                    </h5>
                                    <p>
                                      {job.jobBasics.jobLocation.area},
                                      {job.jobBasics.jobLocation.city}
                                    </p>
                                    <p>{job.jobBasics.jobLocation.pincode}</p>
                                  </Col>

                                  <Col sm={4} className="text-left-mobile-view">
                                    <Link to={`/employers/candidates/${job._id}`} className="text-dark text-decoration-none">
                                      <p className="fw-bold"><span>{job.appliedJobCount}</span>Applicants</p>
                                    </Link>
                                  </Col>
                                </Row>
                              </Col>
                              <Col sm={4} className="rounded mb-3">
                                <Row className="mt-4">
                                  <Col xs={12} sm={6}>
                                    <p className="mt-14 fw-bold"><span>{job.hiredCount} of{" "}
                                      {job.jobDetails.numberOfPositions}</span>Hired</p>
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                sm={2}
                                className="d-flex align-items-center mb-3"
                              >
                                <Row>
                                  <Col
                                    xs={6}
                                    sm={4}
                                    className="text-center-mobile-view "
                                  ></Col>
                                </Row>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </section>
      
    </div>
  );
}

export default EmployerJobPostListedPageSidebarMenu;
